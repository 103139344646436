import * as axios from 'axios';

export class WillbeAPI {

    instance = axios.create({
        // baseURL: 'http://127.0.0.1:8000/api/',
        baseURL: 'https://backend.willbe.kz/api/',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('api_token')}`
        }
    })

    search(city, query) {
        return this.instance.get(`/search/${city}/${query}`)
            .then(response => response.data);
    }

    getListIslands() {
        return this.instance.get('/islands')
            .then(response => response.data);
    }

    getListCategories(island_id) {
        return this.instance.get(`/categories/${island_id}`)
            .then(response => response.data);
    }

    getListSubcategories(island_id) {
        return this.instance.get(`/subcategories/${island_id}`)
            .then(response => response.data);
    }

    getListRegions() {
        return this.instance.get('/regions')
            .then(response => response.data);
    }
    
    getListCities() {
        return this.instance.get('/cities')
            .then(response => response.data);
    }

    getListObjectTypes() {
        return this.instance.get('/object-types')
            .then(response => response.data);
    }

    getProduct(product_id) {
        return this.instance.get(`/product/${product_id}`)
            .then(response => response.data);
    }

    deleteProduct(product_id) {
        return this.instance.delete(`/product/${product_id}`)
            .then(response => response.data);
    }

    changeInStockProduct(product_id) {
        return this.instance.put(`/product/${product_id}`)
            .then(response => response.data);
    }

    getAllProducts() {
        return this.instance.get(`/products`)
            .then(response => response.data);
    }

    getAllProductsFromSupplier(supplier_id) {
        return this.instance.get(`/supplier-products/${supplier_id}`)
            .then(response => response.data);
    }

    getAllOutOfStockProductsFromSupplier(supplier_id) {
        return this.instance.get(`/supplier-outOfStock-products/${supplier_id}`)
            .then(response => response.data);
    }


    getProductsForTargetCategory(islandID, category_id) {
        return this.instance.get(`/products-from-category/${islandID}/${category_id}`)
            .then(response => response.data);
    }

    getProductsForTargetSubcategory(islandID, subcategory_id) {
        return this.instance.get(`/products-from-subcategory/${islandID}/${subcategory_id}`)
            .then(response => response.data);
    }

    getApiToken(email, password) {
        return this.instance.post(`/login`, { email, password })
            .then(response => response.data);
    }

    requestForRegSupplier(formData) {
        return this.instance.post(`/request-for-reg-supplier`, {formData})
            .then(response => response.data);
    }

    requestForRegLegalEntity(formData) {
        return this.instance.post(`/request-for-reg-legalEntity`, {formData})
            .then(response => response.data);
    }

    // getAddressFromYandexAPI(value) {
    //     return axios.get(`https://geocode-maps.yandex.ru/1.x/?format=json&apikey=9fe6048b-e022-474e-9402-92f8eace01f8&geocode=Астана ${value}`)
    //         .then(response => response.data)
    // }

    registrationForSupplier(formData) {
        return this.instance.post('/registration-for-supplier', {formData})
            .then(response => response.data);
    }

    registrationForLegalEntity(formData) {
        return this.instance.post('/registration-for-legal-entity', {formData})
            .then(response => response.data);
    }

    async getAllSuppliers () {
        return this.instance.get('/suppliers')
            .then(response => response.data);
    }

    getSupplier(supplier_id) {
        return this.instance.get(`/suppliers/${supplier_id}`)
            .then(response => response.data);
    }
    
    updateSupplier(data) {
        const formData = new FormData();
        formData.append('logo', data.logo);
        formData.append('cover', data.cover);
        formData.append('formData', JSON.stringify(data));
        return this.instance.post(`/suppliers`, formData)
            .then(response => response.data);
    }

    async geographyOfActivity_get() {
        let response = await this.instance.get('/geography-of-activity')
        return response.data;
    }

    geographyOfActivity_add(data) {
        const formData = new FormData();
        formData.append('formData', JSON.stringify(data));
        return this.instance.post('/geography-of-activity', formData)
            .then(response => response.data);
    }

    geographyOfActivity_delete(city_id) {
        return this.instance.delete(`/geography-of-activity/${city_id}`)
            .then(response => response.data);
    }

    addProduct(data) {
        const formData = new FormData();
        formData.append('image', data.image);
        formData.append('formData', JSON.stringify(data));
        return this.instance.post('/add-product', formData)
            .then(response => response.data);
    }

    editProduct(data, product_id) {
        const formData = new FormData();
        formData.append('image', data.image);
        formData.append('formData', JSON.stringify(data));
        return this.instance.post(`/edit-product/${product_id}`, formData)
            .then(response => response.data);
    }

    getDataLegalEntityForEdit() {
        return this.instance.get('/legal-entity-date')
            .then(response => response.data);
    }

    editLegalEntity(data) {
        return this.instance.put('/legal-entity-date', {...data})
            .then(response => response.data);
    }

}