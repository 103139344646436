import React, { Component } from 'react';
import Header from '../../../components/header';
import Way from '../../../components/way';
import { AdminLeftMenu } from '../../../components/user-left-menu/user-left-menu';
import { WillbeAPI } from '../../../api/api';
import { connect } from 'react-redux';
import s from './../cabinetPage.module.css';
import styleForm from '../../../form-style.module.css';
import { Formik } from 'formik';
import * as yup from 'yup';
import { withAuthRedirect, withNotAdminRedirect } from '../../../hoc/myHocs';
import { compose } from 'redux';

class AddSupplierPage extends Component {

    willbeAPI = new WillbeAPI();

    onSubmit = (formData, {resetForm}) => {
        this.willbeAPI.registrationForSupplier(formData)
            .then(data => {
                if (data != '') {
                    resetForm({})
                    alert('Поставщик добавлен, поздравляем!')
                } else {
                    console.log('Error')
                }
            });
    }

    renderOptions = (regions, cities) => {
        return regions.map(region => {
            return (<optgroup label={region.title}>
                {cities.map(city => {
                    if (city.region_id === region.id) {
                        return <option key={city.id} value={city.id}>{city.title}</option>
                    }
                })}
            </optgroup>)
        })
    }

    render () {

        let optionsToSelect = this.renderOptions(this.props.regions, this.props.cities);

        let way = [ // Объект для указания пути
            {
                src: '/',
                title: 'Главная'
            },
            {
                src: '/reg',
                title: 'Мой кабинет'
            }
        ]

        // Validate for form
        const validationSchema = yup.object().shape({
            company: yup.string().nullable().required('Объязательное поле'),
            city: yup.string().nullable().required('Выберите вариант'),
            address: yup.string().nullable().required('Выберите вариант'),
            phone: yup.string().nullable().required('Объязательное поле'),
            email: yup.string().nullable().required('Объязательное поле'),
            password: yup.string().nullable().required('Объязательное поле'),
        })

        return (
            <>
            <Header />
            <Way way={way} />
            <div className='container'>
                <div className='wrapper-left'>
                    <AdminLeftMenu />
                </div>
                <div className='wrapper-right'>
                    <div className='bord'>
                        <center><h2>Добавить поставщика</h2></center>
                        <Formik
                            enableReinitialize
                            initialValues={{
                                company: '',
                                city: '1',
                                address: '',
                                phone: '',
                                email: '',
                                password: ''
                            }}
                            validateOnBlur
                            validationSchema={validationSchema}
                            onSubmit={this.onSubmit}
                        >
                            {({ values, errors, touched, setFieldValue, handleChange, handleBlur, isValid, handleSubmit, dirty }) => (
                                <div className={styleForm.welcomeForm}>
                                    <div>
                                        <lavel>Название компании *</lavel>
                                        <input name='company' className={styleForm.input} onChange={handleChange} onBlur={handleBlur} value={values.company || ''} />
                                        {touched.company && errors.company && <p className={styleForm.error}>{errors.company}</p>}
                                    </div>
                                    <div>
                                        <lavel>Выберите город *</lavel>
                                        <select name='city' className={styleForm.selectInput} onChange={handleChange} onBlur={handleBlur} value={values.city || ''}>
                                            {optionsToSelect}
                                        </select>
                                        {touched.city && errors.city && <p className={styleForm.error}>{errors.city}</p>}
                                    </div>
                                    <div>
                                        <lavel>Адрес *</lavel>
                                        <input name='address' className={styleForm.input} onChange={handleChange} onBlur={handleBlur} value={values.address || ''} />
                                        {touched.address && errors.address && <p className={styleForm.error}>{errors.address}</p>}
                                    </div>
                                    <div>
                                        <lavel>Номер телефона *</lavel>
                                        <input name='phone' type='number' className={styleForm.input} onChange={handleChange} onBlur={handleBlur} value={values.phone || ''} />
                                        {touched.phone && errors.phone && <p className={styleForm.error}>{errors.phone}</p>}
                                    </div>
                                    <div>
                                        <lavel>Эл. почта *</lavel>
                                        <input name='email' type='email' className={styleForm.input} onChange={handleChange} onBlur={handleBlur} value={values.email || ''} />
                                        {touched.email && errors.email && <p className={styleForm.error}>{errors.email}</p>}
                                    </div>
                                    <div>
                                        <lavel>Пароль *</lavel>
                                        <input name='password' type='password' className={styleForm.input} onChange={handleChange} onBlur={handleBlur} value={values.password || ''} />
                                        {touched.password && errors.password && <p className={styleForm.error}>{errors.password}</p>}
                                    </div>
                                    <button disabled={!isValid && !dirty} className={styleForm.btnForm} onClick={handleSubmit} type='submit'>Зарегистрировать</button>
                                </div>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    regions: state.geolocations.regions,
    cities: state.geolocations.cities
})

export default compose (
    connect(mapStateToProps, {}),
    withNotAdminRedirect,
    withAuthRedirect
)(AddSupplierPage);