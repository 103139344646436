import React from 'react';
import { NavLink } from 'react-router-dom';
import ls from './listSuppliersPage.module.css';

const SupplierItem = (props) => {

    const renderSuppliers = (suppliers) => {
        return suppliers.map(supplier => {
            console.log(supplier);
            const {id, company_name, logo, min_order_amount, description} = supplier;
            return (
                <div className={ls.itemBlock}>
                    <div className={ls.itemBlock_left}>
                        <NavLink to={`/suppliers/${id}`}>
                            {logo ? (<img src={`https://backend.willbe.kz/storage/images/suppliers/logo/${logo}`} />)
                            : (<img src='https://rusfermer.net/wp-content/plugins/fseo-plugins/plugins/common/img/no-photo-big.gif' />)}
                        </NavLink>
                    </div>
                    <div className={ls.itemBlock_right}>
                        <div className={ls.title}>
                            <NavLink to={`/suppliers/${id}`}>{company_name}</NavLink>
                        </div>
                        <div className={ls.specification}>спецификация: FMCG</div>
                        <div>
                            <div className={ls.item}>
                                <div>минимальный заказ</div>
                                <div>{min_order_amount && min_order_amount + ' тг' || <span className={ls.notInfo}>нет информации</span>}</div>
                            </div>
                            <div className={ls.item}>
                                <div>стоимость доставки</div>
                                <div>БЕСПЛАТНО</div>
                            </div>
                        </div>
                        <div className={ls.description}>{description && description || <span className={ls.notInfo}>нет информации</span>}</div>
                    </div>
                </div>
            )
        })
    }

    return (
        renderSuppliers(props.suppliers)
    )
}

export default SupplierItem;