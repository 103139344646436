import React, { Component } from 'react';

import s from './left-banner.module.css';

export default class LeftBanner extends Component {
    render() {
        return(
            <img className={s.banner} src='https://ecome.famithemes.com/wp-content/uploads/2018/03/widget-product-img.jpg' />
        )
    }
}