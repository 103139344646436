import React, { Component } from 'react';
import Header from '../../components/header';
import ProductCategories from '../../components/product-categories';
import MoreProducts from '../../components/more-products';
import LeftBanner from '../../components/left-banner';
import Way from '../../components/way';

import { WillbeAPI } from '../../api/api';
import { withAuthRedirect } from '../../hoc/myHocs';
import { compose } from 'redux';
import s from './productPage.module.css';
import { NavLink } from 'react-router-dom';

class ProductPage extends Component {

    willbeAPI = new WillbeAPI();

    state = {
        id: null,
        categoryID: null,
        subcategoryID: null,
        category: null,
        subcategory: null,
        supplier_id: null,
        userID: null,
        company_name: null,
        title: null,
        description: null,
        measurement: null,
        weight: null,
        nutritional_value: null,
        composition: null,
        image: null,
        price: null
    }

    componentDidMount() {
        if (this.props.match.params.productID) {
            this.willbeAPI.getProduct(this.props.match.params.productID)
                .then((data) => {
                    this.setState((state) => ({
                        id: data.id,
                        categoryID: data.category_id,
                        subcategoryID: data.subcategory_id,
                        category: data.category.title,
                        subcategory: data.subcategory.title,
                        supplier_id: data.supplier_id,
                        userID: data.user_id,
                        company_name: data.company_name,
                        title: data.title,
                        description: data.description,
                        measurement: data.measurement,
                        weight: data.weight,
                        nutritional_value: data.nutritional_value,
                        composition: data.composition,
                        image: data.image,
                        price: data.price
                    }))
                })
        }
    }

    render() {
        const {id, categoryID, subcategoryID, supplier_id, title, image, userID, description, category, subcategory,
            company_name, measurement, weight, nutritional_value, price, composition} = this.state;
        
        let way = [
            {
                src: '/',
                title: 'Главная'
            },
            {
                src: '/',
                title: 'Категория'
            },
            {
                src: '/',
                title: 'Подкатегория'
            },
            {
                src: '/',
                title: 'title'
            },
        ]

        return(
            <>
                <Header />
                <Way way={way} />
                <div className='container'>
                    <div className='wrapper-left'>
                        <ProductCategories />
                        {/* <LeftBanner /> */}
                    </div>
                    <div className='wrapper-right'>
                        <div className={s.wrapperProduct}>
                            <div className={s.wrapperProduct_left}>
                                {image ? (<img src={`https://backend.willbe.kz/storage/images/products/${image}`} />)
                                : (<img src='https://rusfermer.net/wp-content/plugins/fseo-plugins/plugins/common/img/no-photo-big.gif' />)}
                            </div>
                            <div className={s.wrapperProduct_right}>
                                <h2>{title}</h2>
                                <p><b>Поставщик:</b> <NavLink to={`/suppliers/${supplier_id}`}>{company_name}</NavLink></p>
                                <p><b>Категория:</b> {category}, ({subcategory})</p>
                                <p><b>Масса:</b> {weight}</p>
                                {composition && <p><b>Состав:</b> {composition}</p>}
                                {nutritional_value && <p><b>Пищевая ценность на 100 гр:</b> {nutritional_value}</p>}
                                {description && <p><b>Дополнительное описание:</b> {description}</p>}
                                <h2>{price} тг.</h2>
                                <form>
                                    <input type='number' min='1' />
                                    <input type='submit' value='Добавить в корзину' />
                                </form>
                            </div>
                        </div>
                        {/* <MoreProducts /> */}
                    </div>
                </div>
            </>
        )
    }
}

export default compose (
    withAuthRedirect
)(ProductPage);