import React, { Component } from 'react';
import Header from '../../components/header';
import { NavLink } from 'react-router-dom';
import Way from '../../components/way';

import s from './authorizationPage.module.css';
import { connect } from 'react-redux';
import { thunkGetApiToken } from '../../store/auth';

import { AuthorizationReduxForm } from './authorizationForm';

import { withNotAuthRedirect } from '../../hoc/myHocs';
import { compose } from 'redux';

// import '../../style.css';

class AuthorizationPage extends Component {
    // way = [
    //     {
    //         src: '/',
    //         title: 'Главная'
    //     },
    //     {
    //         src: '/auth',
    //         title: 'Авторизация'
    //     },
    // ]

    onSubmit = (formData) => {
        this.props.thunkGetApiToken(formData);
    }

    render() {
        return(
            <>
                <Header />
                {/* <Way way={this.way} /> */}
                <div className='container'>
                    <div className='welcome_left'>
                        {/* <center><h2>Новый формат b2b торговли</h2></center> */}
                        <center><h3>Автоматизация бизнеса для продуктовых магазинов и их поставщиков</h3></center>
                        <img src='8742.jpg' />
                    </div>
                    <div className='welcome_right'>
                        <h2>Войти</h2>
                        <AuthorizationReduxForm onSubmit={this.onSubmit} />
                        <div className='welcome-span'>Если у Вас ещё нет аккаунта, <NavLink to='/reg'>зарегистрируйтесь</NavLink> в системе</div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({});

export default compose (
    connect(mapStateToProps, {thunkGetApiToken}),
    withNotAuthRedirect
)(AuthorizationPage);