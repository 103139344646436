import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { updateIslands, thunkGetCategoriesAndSubcategoriesFromIslandID } from '../store/categories';
import { updateRegions, updateCities } from '../store/geolocations/geolocations';
import { WillbeAPI } from '../api/api';

import MainPage from '../pages/mainPage';
import AuthorizationPage from '../pages/authorizationPage';
import RegistrationPage from '../pages/registrationPage';
import ProductPage from '../pages/productPage';
import SupplierPage from '../pages/supplierPage';
import SupplierCabinetPage from '../pages/supplierCabinetPage/supplierCabinetPage';
import EditProfileSupplierPage from '../pages/supplierCabinetPage/editProfileSupplierPage/editProfileSupplierPage';
import AddProductsPage from '../pages/supplierCabinetPage/addProductsPage/addProductsPage';
import SupplierCabinetProductsPage from '../pages/supplierCabinetPage/supplierCabinetProductsPage/supplierCabinetProductsPage';
import EditProductsPage from '../pages/supplierCabinetPage/editProductsPage/editProductsPage';
import AdminCabinetPage from '../pages/adminCabinetPage/adminCabinetPage';
import AddSupplierPage from '../pages/adminCabinetPage/addSupplierPage/addSupplierPage';
import AddLegalEntitiesPage from '../pages/adminCabinetPage/addLegalEntitiesPage/addLegalEntitiesPage';
import GeographyOfActivityPage from '../pages/supplierCabinetPage/geographyOfActivityPage/geographyOfActivityPage';
import ListSuppliersPage from '../pages/listSuppliersPage';
import LegalEntityCabinetPage from '../pages/legalEntityCabinetPage';
import EditProfileLegalEntityPage from '../pages/legalEntityCabinetPage/editProfileLegalEntityPage/editProfileLegalEntityPage';
import SearchPage from '../pages/searchPage';

class App extends Component {

    willbeAPI = new WillbeAPI();

    componentDidMount() {
        this.willbeAPI.getListIslands().then(data => {
            this.props.updateIslands(data);
        })
        this.props.thunkGetCategoriesAndSubcategoriesFromIslandID(this.props.islandID);

        this.willbeAPI.getListRegions().then(this.props.updateRegions);
        this.willbeAPI.getListCities().then(this.props.updateCities);
    }

    render() {
        return (
            <Router>
                <Route path='/' exact><MainPage page='main' /></Route>                
                <Route path='/auth' exact><AuthorizationPage /></Route>
                <Route path='/reg' exact component={RegistrationPage} />
                {/* <Route path='/cabinet' component={CabinetPage} /> */}

                {/* Admin profile route local position */}
                <Route path='/admin-cabinet' exact component={AdminCabinetPage} />
                <Route path='/admin-cabinet/add-supplier' exact component={AddSupplierPage} />
                <Route path='/admin-cabinet/add-legal-entity' exact component={AddLegalEntitiesPage} />
                {/* end */}

                {/* Legal entity profile route local position */}
                <Route path='/cabinet' exact component={LegalEntityCabinetPage} />
                <Route path='/cabinet/settings' exact component={EditProfileLegalEntityPage} />
                {/* end */}
                
                {/* Supplier profile route local position */}
                <Route path='/supplier-cabinet' exact component={SupplierCabinetPage} />
                <Route path='/supplier-cabinet/edit-profile' exact component={EditProfileSupplierPage} />
                <Route path='/supplier-cabinet/edit-products/:productID' exact component={EditProductsPage} />
                <Route path='/supplier-cabinet/add-product' exact component={AddProductsPage} />
                <Route path='/supplier-cabinet/products' exact component={SupplierCabinetProductsPage} />
                <Route path='/supplier-cabinet/geography-of-activity' exact component={GeographyOfActivityPage} />
                {/* end */}
                
                <Route path='/search' component={SearchPage} />
                <Route path='/suppliers' exact component={ListSuppliersPage} />
                <Route path='/suppliers/:supplierID' exact component={SupplierPage} />
                <Route path='/categories/:category' exact><MainPage page='categories' /></Route>
                <Route path='/categories/:category/:subcategory' exact><MainPage page='subcategories' /></Route>
                <Route path='/products' exact><MainPage page='main' /></Route>
                <Route path='/products/:productID' exact component={ProductPage} />
            </Router>
        )
    }
}

const mapStateToProps = (state) => ({
    islandID: state.categories.islandID
});

export default connect(mapStateToProps, {updateIslands, thunkGetCategoriesAndSubcategoriesFromIslandID, updateRegions, updateCities})(App);