import React, { Component } from 'react';
import { connect } from 'react-redux';

import { thunkGetAllProducts, getProducts, thunkGetProductsForTargetSubcategory, thunkGetProductsForTargetCategory } from '../../store/products';

import Header from '../../components/header';
import ProductCategories from '../../components/product-categories';
import MainBanner from '../../components/main-banner';
import Products from '../../components/products';
import Subcategories from '../../components/subcategories';
import LeftBanner from '../../components/left-banner';
import Way from '../../components/way';

import { withAuthRedirect } from '../../hoc/myHocs';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { WillbeAPI } from '../../api/api';

import s from './mainPage.module.css';

class MainPage extends Component {

    willbeAPI = new WillbeAPI();

    islandID = this.props.match.params.islandID ? this.props.match.params.islandID : 1;

    findCategoryID_FromLocalID = (array, local_id) => {
        const res = array.find((e) => e.local_id == local_id);
        return res.id
    }

    findSubcategoryID_FromLocalID = (array, category_id, local_id) => {
        const res = array.find((e) => e.category_id == category_id && e.local_id == local_id);
        return res.id
    }

    componentDidMount() {
        console.log('componentDidMount')
        if (this.props.page === 'main') {
            this.props.thunkGetAllProducts()
                .then(data => {
                    this.setState((state) => ({...state, products: data}))
                })
        } else if (this.props.match.params.category && this.props.match.params.subcategory && this.props.products <= 0) {
            if (this.props.categories.length > 0) {
                const categoryID = this.findCategoryID_FromLocalID(this.props.categories, this.props.match.params.category);
                const subcategoryID = this.findSubcategoryID_FromLocalID(
                    this.props.subcategories,
                    categoryID,
                    this.props.match.params.subcategory
                );
                this.props.thunkGetProductsForTargetSubcategory(this.islandID, subcategoryID)
                    .then(data => {
                        // this.setState((state) => ({...state, products: data}))
                    })
            } else {
                setTimeout(() => {
                    // С перезагрузкой страницы, дожидаемся приход пропса
                    const categoryID = this.findCategoryID_FromLocalID(this.props.categories, this.props.match.params.category);
                    const subcategoryID = this.findSubcategoryID_FromLocalID(
                        this.props.subcategories,
                        categoryID,
                        this.props.match.params.subcategory
                    );
                    this.props.thunkGetProductsForTargetSubcategory(this.islandID, subcategoryID)
                        .then(data => {
                            // this.setState((state) => ({...state, products: data}))
                        })
                }, 2000)
            }
        } else if (this.props.match.params.category && this.props.products <= 0) {
            if (this.props.categories.length > 0) {
                const categoryID = this.findCategoryID_FromLocalID(this.props.categories, this.props.match.params.category);
                 this.props.thunkGetProductsForTargetCategory(this.islandID, categoryID)
                    .then(data => {
                        // this.setState((state) => ({...state, products: data}))
                    })
            } else {
                setTimeout(() => {
                    // С перезагрузкой страницы, дожидаемся приход пропса
                    const categoryID = this.findCategoryID_FromLocalID(this.props.categories, this.props.match.params.category);
                    this.props.thunkGetProductsForTargetCategory(this.islandID, categoryID)
                        .then(data => {
                            // this.setState((state) => ({...state, products: data}))
                        })
                }, 2000);
            }
        }
    }

    onDeleteProduct = (product_id) => {
        const conf = window.confirm('Вы уверены, что хотите удалить товар?');
        if (conf) {
            this.willbeAPI.deleteProduct(product_id).then(data => {
                let newArrayProducts = this.props.products.filter((item) => product_id != item.id)
                this.props.getProducts(newArrayProducts);
            })
        }
    }

    onChangeInStockProduct = (product_id) => {
        this.willbeAPI.changeInStockProduct(product_id).then(data => {
            let newArrayProducts = this.props.products.filter((item) => product_id != item.id)
            this.props.getProducts(newArrayProducts);
        })
    }

    render() {
        let way = [{src: '/', title: 'Главная'}]
        return(
            <>
            <Header />
            <Way way={way} />
            <div className='container'>
                <div className='wrapper-left'>
                    <ProductCategories />
                    {/* <LeftBanner /> */}
                </div>
                <div className='wrapper-right'>
                    {this.props.page == 'main' && <MainBanner />}
                    {this.props.page === 'categories' && <Subcategories categories={this.props.categories} subcategories={this.props.subcategories} />}
                    {this.props.page === 'subcategories' && <Subcategories categories={this.props.categories} subcategories={this.props.subcategories} />}
                    <Products products={this.props.products} onDeleteProduct={this.onDeleteProduct} onChangeInStockProduct={this.onChangeInStockProduct} />
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    islands: state.categories.islands,
    products: state.products.products,
    categories: state.categories.categories,
    subcategories: state.categories.subcategories
});

export default compose (
    connect(mapStateToProps, {getProducts, thunkGetAllProducts, thunkGetProductsForTargetSubcategory, thunkGetProductsForTargetCategory}),
    withAuthRedirect,
    withRouter
)(MainPage);