import React, { Component } from 'react';
import { WillbeAPI } from '../../../api/api';
import Header from '../../../components/header';
import Way from '../../../components/way';
import { SupplierLeftMenu } from '../../../components/user-left-menu/user-left-menu';
import FormEditProducts from './formEditProducts';
import { compose } from 'redux';
import { withAuthRedirect, withNotUserSupplierRedirect } from '../../../hoc/myHocs';
import { Redirect } from 'react-router';

class EditProductsPage extends Component {

    willbeAPI = new WillbeAPI();

    state = {
        isProgress: false,
        isMine: true,
        product: {
            title: '',
            description: '',
            weight: '',
            nutritional_value: '',
            composition: '',
            image: '',
            price: ''
        }
    }

    componentDidMount() {
        if (this.props.match.params.productID) {
            this.willbeAPI.getProduct(this.props.match.params.productID)
                .then(data => {
                    if (data.supplier_id != localStorage.getItem('supplier_id')) {
                        this.setState((state) => ({...state, isMine: false}))
                    } else {
                        this.setState((state) => ({
                            ...state,
                            isProgress: true,
                            product: {
                                title: data.title,
                                description: data.description,
                                weight: data.weight,
                                nutritional_value: data.nutritional_value,
                                composition: data.composition,
                                image: data.image,
                                price: data.price
                            }
                        }))
                    }
                })
        }
    }

    onSubmitUpdateProduct = (formData) => {
        this.willbeAPI.editProduct(formData, this.props.match.params.productID)
            .then(alert('Информация о продукте обновлена!'))
    }

    render () {
        let way = [ // Объект для указания пути
            {
                src: '/',
                title: 'Главная'
            },
            {
                src: '/reg',
                title: 'Мой кабинет'
            }
        ]

        return (
            <>
            {!this.state.isMine && <Redirect to='/' />}
            <Header />
            <Way way={way} />
            <div className='container'>
                <div className='wrapper-left'>
                    <SupplierLeftMenu supplierID={localStorage.getItem('supplier_id')} />
                    {/* <LeftBanner /> */}
                </div>
                <div className='wrapper-right'>
                    <div className='bord'>
                        <center><h2>Редактирование продукта</h2></center>
                        {this.state.isProgress && <FormEditProducts {...this.state} onSubmit={this.onSubmitUpdateProduct}/>}
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default compose (
    withNotUserSupplierRedirect,
    withAuthRedirect
)(EditProductsPage);