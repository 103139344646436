import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../components/header';
import Way from '../../components/way';
import { SupplierLeftMenu } from '../../components/user-left-menu/user-left-menu';
import Products from '../../components/products';
import { thunkGetAllProductsFromSupplier } from '../../store/products';
import { WillbeAPI } from '../../api/api';
import { withAuthRedirect, withNotUserSupplierRedirect } from '../../hoc/myHocs';
import { compose } from 'redux';
import LeftBanner from '../../components/left-banner';

import s from './supplierCabinetPage.module.css';

class SupplierCabinetPage extends Component {

    render () {
        let way = [ // Объект для указания пути
            {
                src: '/',
                title: 'Главная'
            },
            {
                src: '/reg',
                title: 'Мой кабинет'
            }
        ]
        return (
            <>
            <Header />
            <Way way={way} />
            <div className='container'>
                <div className='wrapper-left'>
                    <SupplierLeftMenu supplierID={localStorage.getItem('supplier_id')} />
                    {/* <LeftBanner /> */}
                </div>
                <div className='wrapper-right'>
                    <div className={s.wrapper_right__left}>
                        {/* <div className={s.indicators}>
                            <div className={s.cabinet_item}>
                                <div>Оборот:    </div>
                                <span className={s.float_right}><b>28 719 380 ₸</b></span>
                            </div>
                            <div className={s.cabinet_item}>
                                <div>Баланс:</div>
                                <span className={s.float_right}><b>32450 ₸</b></span>
                            </div>
                            <div className={s.cabinet_item}>
                                <div>Рейтинг:</div>
                                <span className={s.float_right}><b>2.3 из 5</b></span>
                            </div>
                            <div className={s.cabinet_item}>
                                <div>Комиссия:</div>
                                <span className={s.float_right}><b>0.45%</b></span>
                            </div>
                        </div>
                        <div className={s.applications}>
                            <div className={s.applications_title}>Заявки</div>
                            <div>В ожидании <span className={s.float_right}>333</span></div>
                            <div>В обработке <span className={s.float_right}>333</span></div>
                            <div>На удержании <span className={s.float_right}>333</span></div>
                            <div>Завершенные <span className={s.float_right}>333</span></div>
                            <div>Все заявки <span className={s.float_right}>333</span></div>
                            <div>- - - - - - - - - - - - - - - - - - - - -</div>
                            <div>Отмененные <span className={s.float_right}>333</span></div>
                            <div>Возвраты <span className={s.float_right}>333</span></div>
                        </div> */}
                    </div>
                    <div className={s.board}>
                        Поставщик
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default compose (
    withNotUserSupplierRedirect,
    withAuthRedirect
)(SupplierCabinetPage)