import React, { Component } from 'react';
import { WillbeAPI } from '../../api/api';
import Header from '../../components/header';
import Way from '../../components/way';
import ProductCategories from '../../components/product-categories';
import SupplierItem from './supplier-item';

export default class ListSuppliersPage extends Component {

    willbeAPI = new WillbeAPI();
    state = { suppliers: [] }
    
    componentDidMount() {
        this.willbeAPI.getAllSuppliers()
            .then(suppliers => {
                this.setState(state => ({
                    ...state,
                    suppliers
                }))
            })
    }

    render () {
        let way = [
            {
                src: '/',
                title: 'Главная'
            },
            {
                src: '/suppliers',
                title: 'поставщики'
            },
        ]

        return (
            <>
                <Header />
                <Way way={way} />
                <div className='container'>
                    <div className='wrapper-left'>
                        <ProductCategories />
                        {/* <LeftBanner /> */}
                    </div>
                    <div className='wrapper-right'>
                        <h3>Все поставщики:</h3>
                        <SupplierItem suppliers={this.state.suppliers} />
                    </div>
                </div>
            </>
        )
    }
}