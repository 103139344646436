import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import s from './way.module.css';

export default function  Way(props) {

    function renderWay(arr) {
        return arr.map(({src, title}, index) => {
            if (arr.length === index + 1) {
                return <><NavLink to={src}>{title}</NavLink></>
            } else {
                return <><NavLink to={src}>{title}</NavLink><span>{'=>'}</span></>
            }
        })
    }

    let element = renderWay(props.way);

    return (
        <div className={s.wrapper}>
            {element}
        </div>
    )
}