import React, { Component } from 'react';
import Header from '../../components/header';
import { NavLink } from 'react-router-dom';
import Way from '../../components/way';

import { withNotAuthRedirect } from '../../hoc/myHocs';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { WillbeAPI } from '../../api/api';

import { ForSupplierReduxForm } from './forSupplierForm';
import { ForLegalEntityReduxForm } from './forLegalEntityForm';

import s from './registrationPage.module.css';

class RegistrationPage extends Component {

    willbeAPI = new WillbeAPI();

    state = {
        position: 'legalEntity'
    }

    // way = [
    //     {
    //         src: '/',
    //         title: 'Главная'
    //     },
    //     {
    //         src: '/reg',
    //         title: 'Регистрация'
    //     },
    // ]

    onSupplier = () => { this.setState(state => ({ position: 'supplier' })) }
    onLegalEntity = () => { this.setState(state => ({ position: 'legalEntity' })) }

    onSubmitSupplier = (formData) => {
        this.willbeAPI.requestForRegSupplier (formData)
            .then(data => console.log(data));
    }

    onSubmitLegalEntity = (formData) => {
        this.willbeAPI.requestForRegLegalEntity (formData)
            .then(data => console.log(data));
    }

    a = (state) => {
        if (state === 'legalEntity') {
            return <ForLegalEntityReduxForm onSubmit={this.onSubmitLegalEntity} />
        } else {
            return <ForSupplierReduxForm onSubmit={this.onSubmitSupplier} />
        }
    }

    render() {
        let element = this.a(this.state.position);
        return(
            <>
                <Header />
                {/* <Way way={this.way} /> */}
                <div className='container'>
                    <div className='welcome_left'>
                        <center><h3>Автоматизация бизнеса для продуктовых магазинов и их поставщиков</h3></center>
                        <img src='3627665.jpg' />
                    </div>
                    <div className='welcome_right'>
                        <h2>Заявка на регистрацию</h2>
                        <p><b>Кем Вы являетесь?</b></p>
                        <p><label><input name='position' type='radio' value='legalEntity' defaultChecked onClick={this.onLegalEntity} /> Владелец продуктового магазина</label></p>
                        <p><label><input name='position' type='radio' value='supplier' onClick={this.onSupplier} /> Поставщик</label></p>
                        {element}
                        <div className='welcome-span'>Если у Вас уже есть аккаунт, <NavLink to='/auth'>войдите</NavLink> в систему</div>
                    </div>
                </div>
            </>
        )
    }
}



const mapStateToProps = (state) => ({});

export default compose (
    connect(mapStateToProps, {}),
    withNotAuthRedirect
)(RegistrationPage);