import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import s from '../../../../src/form-style.module.css';

export default function FormEditProducts(props) {
    
    const validationSchema = yup.object().shape({
        title: yup.string().nullable().required('Объязательное поле'),
        weight: yup.string().nullable().required('Объязательное поле'),
        price: yup.string().nullable().required('Объязательное поле'),
    })

    return (
        <Formik
            enableReinitialize
            initialValues={{...props.product}}
            validateOnBlur
            validationSchema={validationSchema}
            onSubmit={props.onSubmit}
        >
            {({ values, errors, touched, setFieldValue, handleChange, handleBlur, isValid, handleSubmit, dirty }) => (
                <div className={s.welcomeForm}>
                    <div>
                        <lavel>Название продукта *</lavel>
                        <input name='title' className={s.input} onChange={handleChange} onBlur={handleBlur} value={values.title || ''} />
                        {touched.title && errors.title && <p className={s.error}>{errors.title}</p>}
                    </div>
                    <div>
                        <lavel>Масса *</lavel>
                        <input name='weight' type='number' className={s.input} onChange={handleChange} onBlur={handleBlur} value={values.weight || ''} />
                        {touched.weight && errors.weight && <p className={s.error}>{errors.weight}</p>}
                    </div>
                    <div>
                        <lavel>Цена *</lavel>
                        <input name='price' className={s.input} type='number' onChange={handleChange} onBlur={handleBlur} value={values.price || ''} />
                        {touched.price && errors.price && <p className={s.error}>{errors.price}</p>}
                    </div>
                    <div>
                        <lavel>Изображение</lavel>
                        <input name='image' className={s.file} type='file' accept='image/*' onChange={(e) => {setFieldValue('image', e.target.files[0])}} onBlur={handleBlur} />
                    </div>
                    <div>
                        <lavel>Состав</lavel>
                        <textarea name='composition' className={s.textarea} onChange={handleChange} onBlur={handleBlur} value={values.composition || ''} />
                    </div>
                    <div>
                        <lavel>Пищевая ценность</lavel>
                        <textarea name='nutritional_value' className={s.textarea} onChange={handleChange} onBlur={handleBlur} value={values.nutritional_value || ''} />
                    </div>
                    <div>
                        <lavel>Описание</lavel>
                        <textarea name='description' className={s.textarea} onChange={handleChange} onBlur={handleBlur} value={values.description || ''} />
                    </div>
                    <button disabled={!isValid && !dirty} className={s.btnForm} onClick={handleSubmit} type='submit'>Добавить</button>
                </div>
            )}
        </Formik>
    )
}