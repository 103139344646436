import { WillbeAPI } from '../../api/api';

let UPDATE_PRODUCTS = 'UPDATE_PRODUCTS';

let initialState = {
    products: []
}

export const searchReducer = (state = initialState, action) => {
    switch(action.type) {
        case UPDATE_PRODUCTS:
            return {
                ...state,
                products: action.products
            }
    }
    return state;
}

export const updateProducts = (products) => ({
    type: UPDATE_PRODUCTS, products
})