import { WillbeAPI } from '../../api/api';

const UPDATE_REGIONS = 'UPDATE_REGIONS';
const UPDATE_CITIES = 'UPDATE_CITIES';

let initialState = {
    regions: [],
    cities: []
}

export const geolocationsReducer = (state = initialState, action) => {
    switch(action.type) {
        case UPDATE_REGIONS:
            return {
                ...state,
                regions: action.regions
            };
        case UPDATE_CITIES:
            return {
                ...state,
                cities: action.cities
            };
    }
    return state;
}

// Тут же пока-что буду creatActions.

export const updateRegions = (regions) => ({
    type: UPDATE_REGIONS, regions
})

export const updateCities = (cities) => ({
    type: UPDATE_CITIES, cities
})


// export const thunkGetCategoriesAndSubcategoriesFromIslandID = (islandID) => (dispatch) => {
//     dispatch(updateIslandID(islandID));
//     let willbeAPI = new WillbeAPI();
//     willbeAPI.getListCategories(islandID).then(data => {
//         dispatch(updateCategories(data));
//     })
//     willbeAPI.getListSubcategories(islandID).then(data => {
//         dispatch(updateSubcategories(data));
//     })
// }