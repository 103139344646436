import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import s from '../../../form-style.module.css';

export default function FormEditLegalEntity (props) {

    // Validate for form
    const validationSchema = yup.object().shape({
        object_name: yup.string().nullable().required('Объязательное поле'),
        phone_number: yup.string().nullable().required('Объязательное поле'),
    })

    return (
        <Formik
            initialValues={{...props}}
            validateOnBlur
            validationSchema={validationSchema}
            onSubmit={values => props.onSubmit(values)}
        >
            {({ values, errors, touched, setFieldValue, handleChange, handleBlur, isValid, handleSubmit, dirty }) => (
                <div className={s.welcomeForm}>
                    <div>
                        <lavel>Название объекта</lavel>
                        <input name='object_name' className={s.input} onChange={handleChange} onBlur={handleBlur} value={values.object_name || ''} />
                        {touched.object_name && errors.object_name && <p className={s.error}>{errors.object_name}</p>}
                    </div>
                    <div>
                        <lavel>Контактный номер телефона</lavel>
                        <input name='phone_number' type='number' className={s.input} onChange={handleChange} onBlur={handleBlur} value={values.phone_number || ''} />
                        {touched.phone_number && errors.phone_number && <p className={s.error}>{errors.phone_number}</p>}
                    </div>
                    <button disabled={!isValid && !dirty} className={s.btnForm} onClick={handleSubmit} type='submit'>Обновить данные</button>
                </div>
            )}
        </Formik>
    )
}