import React, { Component } from 'react';
import Header from '../../../components/header';
import Way from '../../../components/way';
import { LegalEntityLeftMenu } from '../../../components/user-left-menu/user-left-menu';
import FormEditLegalEntity from './formEditLegalEntity';
import { compose } from 'redux';
import { WillbeAPI } from '../../../api/api';
import { withAuthRedirect, withNotUserLegalEntityRedirect } from '../../../hoc/myHocs';

class EditProfileLegalEntityPage extends Component {

    willbeAPI = new WillbeAPI();

    state = {
        isProgress: false,
        object_name: '',
        phone_number: ''
    }

    componentDidMount() {
        this.willbeAPI.getDataLegalEntityForEdit()
            .then(data => {
                this.setState(state => ({
                    ...state,
                    isProgress: true,
                    object_name: data[0].object_name,
                    phone_number: data[0].phone_number
                }))
            })
    }

    onSubmit = (values) => {
        this.willbeAPI.editLegalEntity(values)
            .then(alert('Информация обновлена!'))
    }

    render() {

        let way = [ // Объект для указания пути
            {
                src: '/',
                title: 'Главная'
            },
            {
                src: '/cabinet',
                title: 'Мой кабинет'
            },
            {
                src: '/cabinet/settings',
                title: 'Настройки'
            }
        ]
        
        return (
            <>
                <Header />
                <Way way={way} />
                <div className='container'>
                    <div className='wrapper-left'>
                        <LegalEntityLeftMenu />
                        {/* <LeftBanner /> */}
                    </div>
                    <div className='wrapper-right'>
                        <div className='bord'>
                        <center><h2>Редактирование данных</h2></center>
                            {this.state.isProgress && <FormEditLegalEntity {...this.state} onSubmit={this.onSubmit} />}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default compose (
    withNotUserLegalEntityRedirect,
    withAuthRedirect
)(EditProfileLegalEntityPage);