import React, { Component } from 'react';
import ProductItem from '../product-item';

import s from './more-products.module.css';

export default class MoreProducts extends Component {
    render() {
        return(
            <div className={s.wrapper}>
                <h3>Возможно Вас заинтересует</h3>
                <div>
                    <ProductItem />
                    <ProductItem />
                    <ProductItem />
                    <ProductItem />
                </div>
            </div>
        )
    }
}