import React, { Component } from 'react';
import App from './app';
import { applyMiddleware, createStore } from 'redux';
import rootReducer from '../store/reducers';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';

const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));
window.store = store;

export default class AppContainer extends Component {
    render() {
        return (
            <Provider store={store}>
                <App />
            </Provider>
        )
    }
}