import React from 'react';
import { NavLink } from 'react-router-dom';
import s from './user-left-menu.module.css';

export const LegalEntityLeftMenu = (props) => {
    return (
        <div className={s.cabinet_menu}>
            <ul>
                <li><NavLink to='/cabinet/'>Мой кабинет</NavLink></li>
                <li><NavLink to='/cabinet/applications'>Мои заявки</NavLink></li>
                <li><NavLink to='/cabinet/basket'>Моя корзина</NavLink></li>
                <li><NavLink to='/cabinet/settings'>Настройки</NavLink></li>
                <li><NavLink to='/auth' onClick={() => localStorage.clear()}>Выйти</NavLink></li>
            </ul>
        </div>
    )
}

export const SupplierLeftMenu = (props) => {
    return (
        <div className={s.cabinet_menu}>
            <ul>
                <li><NavLink to='/supplier-cabinet'>Мой кабинет</NavLink></li>
                <li><NavLink to={`/suppliers/${props.supplierID}`}>Мой профиль</NavLink></li>
                <li><NavLink to='/supplier-cabinet/products'>Мои продукты</NavLink></li>
                <li><NavLink to='/supplier-cabinet'>Мои заявки</NavLink></li>
                <li><NavLink to='/supplier-cabinet/add-product'>Добавить продукт</NavLink></li>
                <li><NavLink to='/supplier-cabinet/geography-of-activity'>География деятельности</NavLink></li>
                <li><NavLink to='/supplier-cabinet/edit-profile'>Настройки</NavLink></li>
                <li><NavLink to='/auth'
                    onClick={() => {
                        localStorage.clear();
                    }}>Выйти</NavLink></li>
            </ul>
        </div>
    )
}

export const AdminLeftMenu = (props) => {
    return (
        <div className={s.cabinet_menu}>
            <ul>
                <li><NavLink to='/admin-cabinet/'>My cabinet</NavLink></li>
                <li><NavLink to='/admin-cabinet/add-supplier'>Add supplier</NavLink></li>
                <li><NavLink to='/admin-cabinet/add-legal-entity'>Add seller</NavLink></li>
                <li><NavLink to='/auth' onClick={() => localStorage.clear()}>Go out</NavLink></li>
            </ul>
        </div>
    )
}