import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Header from '../../../components/header';
import Way from '../../../components/way';
import { SupplierLeftMenu } from '../../../components/user-left-menu/user-left-menu';
import Products from '../../../components/products';
import { thunkGetAllProductsFromSupplier } from '../../../store/products';
import { compose } from 'redux';
import { withAuthRedirect, withNotUserSupplierRedirect } from '../../../hoc/myHocs';
import { WillbeAPI } from '../../../api/api';

import LeftBanner from '../../../components/left-banner';

class SupplierCabinetProductsPage extends Component {

    willbeAPI = new WillbeAPI();

    state = {
        countOutOfStock: 0,
        countIsInStock: 0,
        products: []
    }

    componentDidMount() {
        console.log('Baha')
        this.props.thunkGetAllProductsFromSupplier(localStorage.getItem('supplier_id'))
            .then((data) => {
                this.setState((state) => ({
                    ...state,
                    countOutOfStock: data.countOutOfStock,
                    countIsInStock: data.products.length,
                    products: data.products
                }))
            })
    }

    onDeleteProduct = (product_id) => {
        const conf = window.confirm('Вы уверены, что хотите удалить товар?');
        if (conf) {
            this.willbeAPI.deleteProduct(product_id).then(data => {
                this.props.thunkGetAllProductsFromSupplier(localStorage.getItem('supplier_id'))
                    .then((data) => {
                        console.log(data)
                        this.setState((state) => ({
                            ...state,
                            countOutOfStock: data.countOutOfStock,
                            countIsInStock: data.products.length,
                            products: data.products
                        }))
                    })
            })
        }
    }

    onChangeInStockProduct = (product_id) => {
        this.willbeAPI.changeInStockProduct(product_id).then(data => {
            this.props.thunkGetAllProductsFromSupplier(localStorage.getItem('supplier_id'))
                .then((data) => {
                    console.log(data)
                    this.setState((state) => ({
                        ...state,
                        countOutOfStock: data.countOutOfStock,
                        countIsInStock: data.products.length,
                        products: data.products
                    }))
                })
        })
    }

    onIsInStockProducts = (value) => {
        if (value) {
            console.log('true')
            this.props.thunkGetAllProductsFromSupplier(localStorage.getItem('supplier_id'))
                .then((data) => {
                    console.log(data)
                    this.setState((state) => ({
                        ...state,
                        countOutOfStock: data.countOutOfStock,
                        countIsInStock: data.products.length,
                        products: data.products
                    }))
                })
        } else {
            console.log('false')
            this.willbeAPI.getAllOutOfStockProductsFromSupplier(localStorage.getItem('supplier_id'))
                .then((data) => {
                    console.log(data)
                    this.setState((state) => ({
                        ...state,
                        countOutOfStock: data.products.length,
                        countIsInStock: data.countIsInStock,
                        products: data.products
                    }))
                })
        }
    }

    render () {
        let way = [ // Объект для указания пути
            {
                src: '/',
                title: 'Главная'
            },
            {
                src: '/reg',
                title: 'Мой кабинет'
            }
        ]
        
        return (
            <>
            <Header />
            <Way way={way} />
            <div className='container'>
                <div className='wrapper-left'>
                    <SupplierLeftMenu supplierID={localStorage.getItem('supplier_id')} />
                    {/* <LeftBanner /> */}
                </div>
                <div className='wrapper-right'>
                    <div>
                        <button onClick={() => this.onIsInStockProducts(true)}>В наличии ({this.state.countIsInStock})</button>
                        <button onClick={() => this.onIsInStockProducts(false)}>Не в наличии ({this.state.countOutOfStock})</button>
                    </div><br />
                    <Products products={this.state.products} onDeleteProduct={this.onDeleteProduct} onChangeInStockProduct={this.onChangeInStockProduct} />
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    products: state.products.products
})

export default compose (
    connect(mapStateToProps, {thunkGetAllProductsFromSupplier}),
    withNotUserSupplierRedirect,
    withAuthRedirect
)(SupplierCabinetProductsPage);