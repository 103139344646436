import React from 'react';
import { Formik } from 'formik';
import s from '../../../../src/form-style.module.css';

export default function FormEditSupplier (props) {
    return (
        <Formik
            initialValues={{...props}}
            validateOnBlur
            onSubmit={(value) => props.onSubmit(value)}
        >
            {({ values, errors, touched, setFieldValue, handleChange, handleBlur, isValid, handleSubmit, dirty }) => (
                <div className={s.welcomeForm}>
                    <div>
                        <lavel>Адрес</lavel>
                        <input name='address' className={s.input} onChange={handleChange} onBlur={handleBlur} value={values.address || ''} />
                    </div>
                    <div>
                        <lavel>Описание</lavel>
                        <textarea name='description' className={s.textarea} onChange={handleChange} onBlur={handleBlur} value={values.description || ''} />
                    </div>
                    <div>
                        <lavel>Год основания</lavel>
                        <input name='year_of_foundation' type='number' className={s.input} onChange={handleChange} onBlur={handleBlur} value={values.year_of_foundation || ''} />
                    </div>
                    <div>
                        <lavel>Минимальная сумма заявки</lavel>
                        <input name='min_order_amount' type='number' className={s.input} onChange={handleChange} onBlur={handleBlur} value={values.min_order_amount || ''} />
                    </div>
                    <div>
                        <lavel>Логотип</lavel>
                        <input name='logo' className={s.file} type='file' accept='image/*' onChange={(e) => {setFieldValue('logo', e.target.files[0])}} onBlur={handleBlur} />
                    </div>
                    <div>
                        <lavel>Обложка</lavel>
                        <input name='cover' className={s.file} type='file' accept='image/*' onChange={(e) => {setFieldValue('cover', e.target.files[0])}} onBlur={handleBlur} />
                    </div>
                    <div>
                        <lavel>Контактный email</lavel>
                        <input name='contact_email' className={s.input} onChange={handleChange} onBlur={handleBlur} value={values.contact_email || ''} />
                    </div>
                    <div>
                        <lavel>Контактный номер телефона</lavel>
                        <input name='contact_phone_number' className={s.input} onChange={handleChange} onBlur={handleBlur} value={values.contact_phone_number || ''} />
                    </div>
                    <button disabled={!isValid && !dirty} className={s.btnForm} onClick={handleSubmit} type='submit'>Обновить данные</button>
                </div>
            )}
        </Formik>
    )
}