import React, { Component } from 'react';
import { WillbeAPI } from '../../../api/api';
import { withRouter } from 'react-router-dom';
import * as qs from 'query-string';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { updateProducts } from '../../../store/search/search-reducer';

import s from './main-head.module.css';

class MainHead extends Component {

    willbeAPI = new WillbeAPI();
    queryFromUrl = qs.parse(this.props.location.search);
    state = {query: this.queryFromUrl.q, city: this.queryFromUrl.city}

    onChangeForQuery = (e) => {
        this.setState(state => ({...state, query: e.target.value}))
    }

    onChangeForCity = (e) => {
        this.setState(state => ({...state, city: e.target.value}))
    }

    sendForm = (e) => {
        e.preventDefault();
        this.willbeAPI.search(e.target.city.value, e.target.query.value)
            .then(products => {
                this.props.updateProducts(products);
                this.props.history.push('/search?q=' + e.target.query.value + '&city=' + e.target.city.value);
            })
    }

    renderOptionsForCity = (regions, cities) => {
        return regions.map(region => {
            return (<optgroup label={region.title}>
                {cities.map(city => {
                    if (city.region_id === region.id) {
                        return <option key={city.id} value={city.id}>{city.title}</option>
                    }
                })}
            </optgroup>)
        })
    }

    render() {
        let optionsForCity = this.renderOptionsForCity(this.props.regions, this.props.cities);
        return(
            <div className={s.mainHeadWrapper}>
                <div className='container'>
                    <div className={s.left}>
                        <NavLink to='/'><img src='/icon.svg' /></NavLink>
                    </div>
                    <div className={s.center}>
                        <form className={s.form} onSubmit={(e) => this.sendForm(e)}>
                            <input
                                type='text'
                                className={s.searchInput}
                                value={this.state.query}
                                onChange={this.onChangeForQuery}
                                name='query'
                                placeholder='Введите интересующий Вас продукт'
                            />
                            <select className={s.selectInput} name='city' onChange={this.onChangeForCity} value={this.state.city}>
                                {optionsForCity}
                            </select>
                            <input
                                type='submit'
                                className={s.btnSearch}
                                value='Искать'
                            />
                        </form>
                        <div className={s.popularQueries}>
                            Популярные запросы:
                            <a href='' className={s.keyItem}>хлеб</a>,
                            <a href='' className={s.keyItem}>пельмени</a>,
                            <a href='' className={s.keyItem}>картошка</a>,
                            <a href='' className={s.keyItem}>масло</a>,
                            <a href='' className={s.keyItem}>чай</a>
                        </div>
                    </div>
                    {/* <div className={s.right}>
                        <span className={`display-block ${s.callMe}`}>По всем вопросам:</span>
                        <a href='tel:+77014074130' className={`display-block ${s.phone}`}>+7 (701) 407 41 30</a>
                        <span className={`display-block ${s.mail}`}>info@willbe.kz</span>
                    </div> */}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    regions: state.geolocations.regions,
    cities: state.geolocations.cities
})

export default connect(mapStateToProps, {updateProducts})(withRouter(MainHead));