import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { required, maxLengthCreater } from '../../utilits/validators';
import { Input } from '../../components/common/FormsControls/FormControls';
import { NavLink } from 'react-router-dom';

const maxLength50 = maxLengthCreater(50);

const AuthorizationForm = (props) => {
    return (
        <form className='welcome-form' onSubmit={props.handleSubmit}>
            <Field className='input' component={Input} name='email' placeholder='Эл. почта'
                validate={[required, maxLength50]} />
            <Field className='input' type='password' component={Input} className='input'
                name='password' placeholder='Пароль' validate={[required, maxLength50]} />
            <label><input type='checkbox' />Запомнить меня</label>
            <NavLink to='/emilia' className='float-right'>Забыл пароль</NavLink>
            <p><input type='submit' className='btnForm' value='Войти' /></p>
        </form>
    )
}

export const AuthorizationReduxForm = reduxForm({form: 'authorizationForm'})(AuthorizationForm);