import { WillbeAPI } from '../../api/api';

const UPDATE_ISLAND_ID = 'UPDATE_ISLAND_ID';
const UPDATE_ISLANDS = 'UPDATE_ISLANDS';
const UPDATE_CATEGORIES = 'UPDATE_CATEGORIES';
const UPDATE_SUBCATEGORIES = 'UPDATE_SUBCATEGORIES';

let initialState = {
    islands: [],
    categories: [],
    subcategories: [],
    islandID: 1,
}

export const categoriesReducer = (state = initialState, action) => {
    switch(action.type) {
        case UPDATE_ISLANDS:
            return {
                ...state,
                islands: action.islands
            };
        case UPDATE_CATEGORIES:
            return {
                ...state,
                categories: action.categories
            };
        case UPDATE_SUBCATEGORIES:
            return {
                ...state,
                subcategories: action.subcategories
            };
        case UPDATE_ISLAND_ID:
            return {
                ...state,
                islandID: action.islandID,
            }
    }
    return state;
}

// Тут же пока-что буду creatActions.

export const updateIslands = (islands) => ({
    type: UPDATE_ISLANDS, islands
})

export const updateCategories = (categories) => ({
    type: UPDATE_CATEGORIES, categories
})

export const updateSubcategories = (subcategories) => ({
    type: UPDATE_SUBCATEGORIES, subcategories
})

export const updateIslandID = (islandID) => ({
    type: UPDATE_ISLAND_ID, islandID
})


export const thunkGetCategoriesAndSubcategoriesFromIslandID = (islandID) => (dispatch) => {
    dispatch(updateIslandID(islandID));
    let willbeAPI = new WillbeAPI();
    willbeAPI.getListCategories(islandID).then(data => {
        dispatch(updateCategories(data));
    })
    willbeAPI.getListSubcategories(islandID).then(data => {
        dispatch(updateSubcategories(data));
    })
}