import react, { Component } from 'react';
import '../../../style.css';
import s from './top-banner.module.css';

export default class TopBanner extends Component {

    render() {
        return (
            <div className={s.banner_wrapper}>
                <img src={`https://ecome.famithemes.com/wp-content/uploads/2018/03/Banner-top-1.jpg`} />
                {/* <div className='container'>this will be TopBanner</div> */}
            </div>
        )
    }
}