import React, { Component } from 'react';
import { WillbeAPI } from '../../api/api';
import * as qs from 'query-string';
import Header from '../../components/header';
import Way from '../../components/way';
import ProductCategories from '../../components/product-categories';
import Products from '../../components/products';
import { connect } from 'react-redux';
import { updateProducts } from '../../store/search/search-reducer';

class SearchPage extends Component {

    willbeAPI = new WillbeAPI();

    componentDidMount() {
        const queryFromUrl = qs.parse(this.props.location.search);
        this.willbeAPI.search(queryFromUrl.city, queryFromUrl.q)
            .then(products => {
                this.props.updateProducts(products);
                this.props.history.push('/search?q=' + queryFromUrl.q + '&city=' + queryFromUrl.city);
            })
    }

    onDeleteProduct = (product_id) => {
        const conf = window.confirm('Вы уверены, что хотите удалить товар?');
        if (conf) {
            this.willbeAPI.deleteProduct(product_id).then(data => {
                let newArrayProducts = this.props.products.filter((item) => product_id != item.id)
                this.props.getProducts(newArrayProducts);
            })
        }
    }

    onChangeInStockProduct = (product_id) => {
        this.willbeAPI.changeInStockProduct(product_id).then(data => {
            let newArrayProducts = this.props.products.filter((item) => product_id != item.id)
            this.props.getProducts(newArrayProducts);
        })
    }

    render() {
        const way = [{src: '/', title: 'Главная'}, {src: '/search', title: 'Поиск товаров'}]
        const queryFromUrl = qs.parse(this.props.location.search);
        return (
            <>
                <Header />
                <Way way={way} />
                <div className='container'>
                    <div className='wrapper-left'>
                        {this.props.categories}
                        <ProductCategories />
                        {/* <LeftBanner /> */}
                    </div>
                    <div className='wrapper-right'>
                        {this.props.products.length > 0 && <h3>Результаты по запросу "{queryFromUrl.q}", найдено результатов - {this.props.products.length}</h3>}
                        {this.props.products.length < 1 && <h3>По запросу "{queryFromUrl.q}" ничего не найдено</h3>}
                        <Products products={this.props.products} onDeleteProduct={this.onDeleteProduct} onChangeInStockProduct={this.onChangeInStockProduct} />
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    products: state.search.products
});

export default connect(mapStateToProps, {updateProducts})(SearchPage);