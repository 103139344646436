import React, { Component } from 'react';
import Header from '../../components/header';
import Way from '../../components/way';
import { LegalEntityLeftMenu } from '../../components/user-left-menu/user-left-menu';

import { compose } from 'redux';
import { withAuthRedirect, withNotUserLegalEntityRedirect } from '../../hoc/myHocs';

class LegalEntityCabinetPage extends Component {

    render() {

        let way = [ // Объект для указания пути
            {
                src: '/',
                title: 'Главная'
            },
            {
                src: '/cabinet',
                title: 'Мой кабинет'
            }
        ]
        return (
            <>
                <Header />
                <Way way={way} />
                <div className='container'>
                    <div className='wrapper-left'>
                        <LegalEntityLeftMenu />
                        {/* <LeftBanner /> */}
                    </div>
                    <div className='wrapper-right'>
                        You are legal entity
                    </div>
                </div>
            </>
        )
    }
}

export default compose (
    withNotUserLegalEntityRedirect,
    withAuthRedirect
)(LegalEntityCabinetPage);