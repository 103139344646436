import React, { Component } from 'react';
import s from './main-banner.module.css';

export default class MainBanner extends Component {
    render() {
        return(
            <div className={s.banner}>
                {/* <img src='https://ecome.famithemes.com/wp-content/uploads/2018/03/Banner-in-cat.jpg' /> */}
                <img src='https://ae01.alicdn.com/kf/Hf6284e8e0b16450db883bb3eacb55376i.png_.webp' />
                {/* <img src='https://ae01.alicdn.com/kf/H1b91218931114add9de94f5150e86bc4q.png_.webp' /> */}
            </div>
        )
    }
}