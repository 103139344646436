import React from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';

let mapStateToProps = (state) => ({
    isAuth: state.auth.isAuth
});

export const withAuthRedirect = (Component) => {

    class RedirectComponent extends React.Component {
        render() {
            if (localStorage.getItem('isAuth') !== 'true') return <Redirect to='/auth' />
            return <Component {...this.props} />
        }
    }

    return connect(mapStateToProps)(RedirectComponent);
}

export const withNotAuthRedirect = (Component) => {

    class RedirectComponent extends React.Component {
        render() {
            if (localStorage.getItem('isAuth') === 'true' && localStorage.getItem('user_type') === '3') return <Redirect to='/cabinet' />
            if (localStorage.getItem('isAuth') === 'true' && localStorage.getItem('user_type') === '2') return <Redirect to='/supplier-cabinet' />
            if (localStorage.getItem('isAuth') === 'true' && localStorage.getItem('user_type') === '1') return <Redirect to='/admin-cabinet' />
            return <Component {...this.props} />
        }
    }

    return connect(mapStateToProps)(RedirectComponent);
}

export const withNotUserSupplierRedirect = (Component) => {

    class RedirectComponent extends React.Component {
        render() {
            if (localStorage.getItem('supplier_id') === 'null') return <Redirect to='/' />
            return <Component {...this.props} />
        }
    }

    return connect(mapStateToProps)(RedirectComponent);
}

export const withNotUserLegalEntityRedirect = (Component) => {

    class RedirectComponent extends React.Component {
        render() {
            if (localStorage.getItem('user_type') !== '3') return <Redirect to='/' />
            return <Component {...this.props} />
        }
    }

    return connect(mapStateToProps)(RedirectComponent);
}

export const withNotAdminRedirect = (Component) => {

    class RedirectComponent extends React.Component {
        render() {
            if (localStorage.getItem('user_type') !== '1') return <Redirect to='/' />
            return <Component {...this.props} />
        }
    }

    return connect(mapStateToProps)(RedirectComponent);
}