import React, { Component } from 'react';
import { WillbeAPI } from '../../../api/api';
import * as axios from 'axios';
import Header from '../../../components/header';
import Way from '../../../components/way';
import s from '../../../form-style.module.css';
import { AdminLeftMenu } from '../../../components/user-left-menu/user-left-menu';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { withAuthRedirect, withNotAdminRedirect } from '../../../hoc/myHocs';
import * as yup from 'yup';
import { compose } from 'redux';

class AddLegalEntitiesPage extends Component {

    willbeAPI = new WillbeAPI();
    state = { address: [], objectTypes: [] }
    coordinates = '';

    componentDidMount() {
        // Get list object types
        this.willbeAPI.getListObjectTypes().then(data => {
            this.setState((state) => ({
                ...state,
                objectTypes: data
            }))
        })
    }

    getAddressFromYandexAPI = (city = 'Астана', address) => {
        localStorage.setItem('addressValue', address);
        axios.get(`https://geocode-maps.yandex.ru/1.x/?format=json&apikey=9fe6048b-e022-474e-9402-92f8eace01f8&geocode=${city} ${address}`)
            .then(response => response.data).then((data) => {
                if (data.response.GeoObjectCollection.featureMember.length > 0) {
                    this.coordinates = data.response.GeoObjectCollection.featureMember[0].GeoObject.Point.pos.split(' ');
                    localStorage.setItem('coordinateX', this.coordinates[0]);
                    localStorage.setItem('coordinateY', this.coordinates[1]);
                    localStorage.setItem('address', data.response.GeoObjectCollection.featureMember[0].GeoObject.name);
                }
                this.setState((state) => ({
                    ...state,
                    address: data.response.GeoObjectCollection.featureMember
                }))
            })
    }

    // Render options for object type
    renderOptionsForObjactType = (objectTypes) => {
        return objectTypes.map(objectType => {
            return <option key={objectType.id} value={objectType.id}>{objectType.title}</option>;
        })
    }

    // Render regions and cities
    renderOptionsForCities = (regions, cities) => {
        return regions.map(region => {
            return (<optgroup label={region.title}>
                {cities.map(city => {
                    if (city.region_id === region.id) {
                        return <option key={city.id} value={city.id}>{city.title}</option>
                    }
                })}
            </optgroup>)
        })
    }

    renderAddress = (arr) => {
        return arr.map((item) => {
            return <option label={localStorage.getItem('addressValue')} value={item.GeoObject.name} />
        })
    }

    // Get city name from cityId
    getCityTitleFromCityID = (cities, cityID) => {
        return cities.map(city => {
            if (city.id == cityID) {
                return city.title;
            }
        })
    }

    // Submit for form
    onSubmit = (formData, {resetForm}) => {
        formData.x = localStorage.getItem('coordinateX');
        formData.y = localStorage.getItem('coordinateY');
        formData.address = localStorage.getItem('address');
        formData.addressValue = localStorage.getItem('addressValue');
        this.willbeAPI.registrationForLegalEntity(formData).then(data => {
            if (data != '') {
                resetForm({})
                alert('Юр лицо добавлено, поздравляем!')
            } else {
                console.log('Error')
            }
        });
    }

    render () {
        let optionsForObjectType = this.renderOptionsForObjactType(this.state.objectTypes);
        let optionsToSelectForCities = this.renderOptionsForCities(this.props.regions, this.props.cities);
        let optionsForAddress = this.renderAddress(this.state.address);

        let way = [ // Объект для указания пути
            {
                src: '/',
                title: 'Главная'
            },
            {
                src: '/reg',
                title: 'Мой кабинет'
            }
        ]

        // Validate for form
        const validationSchema = yup.object().shape({
            company_name: yup.string().nullable().required('Объязательное поле'),
            object_name: yup.string().nullable().required('Выберите вариант'),
            city: yup.string().nullable().required('Выберите вариант'),
            address: yup.string().nullable().required('Объязательное поле'),
            phone: yup.string().nullable().required('Объязательное поле'),
            email: yup.string().nullable().required('Объязательное поле'),
            password: yup.string().nullable().required('Объязательное поле'),
        })

        return (
            <>
            <Header />
            <Way way={way} />
            <div className='container'>
                <div className='wrapper-left'>
                    <AdminLeftMenu />
                </div>
                <div className='wrapper-right'>
                    <div className='bord'>
                        <center><h2>Добавить юр лицо</h2></center>
                        <Formik
                            enableReinitialize
                            initialValues={{
                                company_name: '',
                                object_type: '1',
                                object_name: '',
                                city: '1',
                                address: '',
                                phone: '',
                                email: '',
                                password: ''
                            }}
                            validateOnBlur
                            validationSchema={validationSchema}
                            onSubmit={this.onSubmit}
                        >
                        {({ values, errors, touched, setFieldValue, handleChange, handleBlur, isValid, handleSubmit, dirty }) => (
                            <div className={s.welcomeForm}>
                                <div>
                                    <lavel>Название компании *</lavel>
                                    <input name='company_name' className={s.input} onChange={handleChange}
                                        onBlur={handleBlur} value={values.company_name || ''} />
                                    {touched.company_name && errors.company_name && <p className={s.error}>{errors.company_name}</p>}
                                </div>
                                <div>
                                    <lavel>Тип объекта *</lavel>
                                    <select name='object_type' className={s.selectInput} onChange={handleChange}
                                        onBlur={handleBlur} value={values.object_type || ''}>
                                        {optionsForObjectType}
                                    </select>
                                    {touched.object_type && errors.object_type && <p className={s.error}>{errors.object_type}</p>}
                                </div>
                                <div>
                                    <lavel>Название объекта *</lavel>
                                    <input name='object_name' className={s.input} onChange={handleChange}
                                        onBlur={handleBlur} value={values.object_name || ''} />
                                    {touched.object_name && errors.object_name && <p className={s.error}>{errors.object_name}</p>}
                                </div>
                                <div>
                                    <lavel>Выберите город *</lavel>
                                    <select name='city' className={s.selectInput} onChange={handleChange}
                                        onBlur={handleBlur} value={values.city || ''}>
                                        {optionsToSelectForCities}
                                    </select>
                                    {touched.city && errors.city && <p className={s.error}>{errors.city}</p>}
                                </div>
                                <div>
                                    <lavel>Адрес *</lavel>
                                    <input list='address' autocomplete='off' className={s.input} required name='address'
                                        onChange={(e) => {
                                            this.getAddressFromYandexAPI(
                                            this.getCityTitleFromCityID(this.props.cities, values.city), e.target.value)
                                            handleChange(e)
                                        }} placeholder='Адрес магазина' value={values.address || ''}/>
                                    <datalist id='address'>
                                        {optionsForAddress}
                                    </datalist>
                                    {touched.address && errors.address && <p className={s.error}>{errors.address}</p>}
                                </div>
                                <div>
                                    <lavel>Номер телефона *</lavel>
                                    <input name='phone' type='number' className={s.input} onChange={handleChange}
                                        onBlur={handleBlur} value={values.phone || ''} />
                                    {touched.phone && errors.phone && <p className={s.error}>{errors.phone}</p>}
                                </div>
                                <div>
                                    <lavel>Эл. почта *</lavel>
                                    <input name='email' type='email' className={s.input} onChange={handleChange}
                                        onBlur={handleBlur} value={values.email || ''} />
                                    {touched.email && errors.email && <p className={s.error}>{errors.email}</p>}
                                </div>
                                <div>
                                    <lavel>Пароль *</lavel>
                                    <input name='password' type='password' className={s.input} onChange={handleChange}
                                        onBlur={handleBlur} value={values.password || ''} />
                                    {touched.password && errors.password && <p className={s.error}>{errors.password}</p>}
                                </div>
                                <button disabled={!isValid && !dirty} className={s.btnForm} onClick={handleSubmit} type='submit'>
                                    Зарегистрировать
                                </button>
                            </div>
                        )}
                        </Formik>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    regions: state.geolocations.regions,
    cities: state.geolocations.cities
})

export default compose (
    connect(mapStateToProps, {}),
    withNotAdminRedirect,
    withAuthRedirect
)(AddLegalEntitiesPage);