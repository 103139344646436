import React from 'react';
import s from './formsControls.module.css';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export const Input = ({input, meta, value, ...props}) => {
    const hasError =  meta.touched && meta.error;
    return (
        <div className={s.formControl + ' ' + (hasError && s.error)}>
            <div>
                <input {...input} {...props} value={value} />
            </div>
            { hasError && <span>{meta.error}</span> }
        </div>
    )
}

export const SelectCity = ({input, meta, ...props}) => {
    const hasError =  meta.touched && meta.error;
    return (
        <div className={s.formControl + ' ' + (hasError && s.error)}>
            <div>
                <select {...input} {...props}>
                    <option disabled>Выберите город</option>
                    <option value='Астана'>Астана</option>
                </select>
            </div>
            { hasError && <span>{meta.error}</span> }
        </div>
    )
}

export const InputPhone = ({input, meta, inputClass, ...props}) => {
    const hasError =  meta.touched && meta.error;
    return (
        <div className={s.formControl + ' ' + (hasError && s.error)}>
            <div>
                <PhoneInput
                    {...input} {...props}
                    inputClass={inputClass}
                    containerClass={inputClass}
                    country='kz'
                    onlyCountries={['kz']}
                    masks={{kz: '(...) ...-..-..'}}
                />
            </div>
            { hasError && <span>{meta.error}</span> }
        </div>
    )
}