import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import s from './user-navigation.module.css';

export default class UserNavigation extends Component {

    if = () => {
        if (!localStorage.getItem('isAuth')) {
            return <><NavLink to='/auth' className={s.link}>вход</NavLink> | <NavLink to='/reg' className={s.link}>регистрация</NavLink></>
        } else if (localStorage.getItem('isAuth') === 'true' && localStorage.getItem('user_type') === '2') {
            return <NavLink to='/supplier-cabinet' className={s.link}>мой кабинет</NavLink>
        } else if (localStorage.getItem('user_type') === '1') {
            return <NavLink to='/admin-cabinet' className={s.link}>мой кабинет</NavLink>
        } else if (localStorage.getItem('user_type') === '3') {
            return <NavLink to='/cabinet' className={s.link}>мой кабинет</NavLink>
        }
    }

    render() {
        return (
            <div className={s.wrapper}>
                <div className="container">
                    <span className='float-right'>
                        {this.if()}
                        {/* {localStorage.getItem('isAuth') === 'true' && localStorage.getItem('user_type') === '2' && <NavLink to='/supplier-cabinet' className={s.link}>мой кабинет</NavLink>}
                        {localStorage.getItem('user_type') === '1' && <NavLink to='/admin-cabinet' className={s.link}>мой кабинет</NavLink>}
                        {localStorage.getItem('isAuth') === '' && <NavLink to='/auth' className={s.link}>вход</NavLink> | <NavLink to='/reg' className={s.link}>регистрация</NavLink>} */}
                    </span>
                </div>
            </div>
        )
    }
}