import { WillbeAPI } from '../../api/api';

const UPDATE_AUTH_STATUS = 'UPDATE_AUTH_STATUS';

let initialState = {
    id: null,
    user_type: null,
    api_token: null,
    supplier_id: null,
    isAuth: false,
}

export const authReducer = (state = initialState, action) => {
    switch(action.type) {
        case UPDATE_AUTH_STATUS:
            return {
                ...state,
                isAuth: !state.isAuth,
                id: action.id,
                api_token: action.api_token,
                user_type: action.user_type,
                supplier_id: action.supplier_id
            }
    }
    return state;
}

// Тут же пока-что буду creatActions.

export const updateAuthStatus = (api_token, user_type, id, supplier_id) => ({
    type: UPDATE_AUTH_STATUS, api_token, user_type, id, supplier_id
})

export const thunkGetApiToken = ({email, password}) => (dispatch) => {
    let willbeAPI = new WillbeAPI();
    willbeAPI.getApiToken(email, password).then(data => {
            if (data != '') {
                console.log(data)
                localStorage.setItem('id', data.id);
                localStorage.setItem('supplier_id', data.supplier_id);
                localStorage.setItem('user_type', data.user_type);
                localStorage.setItem('api_token', data.api_token);
                localStorage.setItem('isAuth', true);
                dispatch(updateAuthStatus(data.api_token, data.user_type, data.id, data.supplier_id));
            }
        })
}