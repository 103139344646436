import React, { Component } from 'react';
import { WillbeAPI } from '../../../api/api';
import Header from '../../../components/header';
import Way from '../../../components/way';
import { SupplierLeftMenu } from '../../../components/user-left-menu/user-left-menu';
import FormEditSupplier from './formEditSupplier';
import { compose } from 'redux';
import { withAuthRedirect, withNotUserSupplierRedirect } from '../../../hoc/myHocs';

class EditProfileSupplierPage extends Component {

    willbeAPI = new WillbeAPI();

    state = {
        isProgress: false,
        form: {
            address: '',
            year_of_foundation: '',
            description: '',
            min_order_amount: '',
            logo: '',
            cover: '',
            contact_email: '',
            contact_phone_number: ''
        }
    }

    componentDidMount() {
        this.willbeAPI.getSupplier(localStorage.getItem('supplier_id'))
            .then(data => {
                this.setState((state) => ({
                    ...state,
                    isProgress: true,
                    form: {
                        address: data.address,
                        year_of_foundation: data.year_of_foundation,
                        description: data.description,
                        min_order_amount: data.min_order_amount,
                        logo: data.logo,
                        cover: data.cover,
                        contact_email: data.contact_email,
                        contact_phone_number: data.contact_phone_number
                    }
                }))
            })
    }

    onSubmitUpdateInfoSupplier = (formData) => {
        this.willbeAPI.updateSupplier(formData)
            .then(data => {
                this.setState((state) => ({
                    ...state,
                    form: {
                        address: data.address,
                        year_of_foundation: data.year_of_foundation,
                        description: data.description,
                        min_order_amount: data.min_order_amount,
                        logo: data.logo,
                        cover: data.cover,
                        contact_email: data.contact_email,
                        contact_phone_number: data.contact_phone_number
                    }
                }))
                alert('Информация обновлена!')
            })
    }

    render () {
        let way = [ // Объект для указания пути
            {
                src: '/',
                title: 'Главная'
            },
            {
                src: '/reg',
                title: 'Мой кабинет'
            }
        ]

        return (
            <>
            <Header />
            <Way way={way} />
            <div className='container'>
                <div className='wrapper-left'>
                    <SupplierLeftMenu supplierID={localStorage.getItem('supplier_id')} />
                    {/* <LeftBanner /> */}
                </div>
                <div className='wrapper-right'>
                    <div className='bord'>
                        <center><h2>Редактирование данных</h2></center>
                        {this.state.isProgress && <FormEditSupplier {...this.state.form} onSubmit={this.onSubmitUpdateInfoSupplier}/>}
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default compose (
    withNotUserSupplierRedirect,
    withAuthRedirect
)(EditProfileSupplierPage);