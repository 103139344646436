import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { thunkGetProductsForTargetSubcategory } from '../../store/products';

import s from './subcategories.module.css';

class Subcategories extends Component {

    findCategoryID_FromLocalID = (array, local_id) => {
        const res = array.find((e) => e.local_id == local_id);
        return res.id
    }

    onUpdateProducts = (island_id, subcategory_id) => {
        this.props.thunkGetProductsForTargetSubcategory(island_id, subcategory_id);
    }

    renderSubcategories(subcategories, categoryID) {
        return subcategories.map((item) => {
            if (item.category_id == categoryID) {
                return (<NavLink to={`/categories/${this.props.match.params.category}/${item.local_id}`} key={item.id} className={s.textWrapper}
                    activeStyle={{
                        fontWeight: '700',
                        color: '#4a7d8d',
                        textDecoration: 'underline'
                    }}
                    onClick={() => {this.onUpdateProducts(item.island_id, item.id)}}>
                    {item.title}
                </NavLink>)
            }
        })
    }

    render() {
        let categoryID = null;
        let elements = null;
        if (this.props.categories.length > 0) {
            categoryID = this.findCategoryID_FromLocalID(this.props.categories, this.props.match.params.category);
            elements = this.renderSubcategories(this.props.subcategories, categoryID);
        } else {
            setTimeout(() => {
                categoryID = this.findCategoryID_FromLocalID(this.props.categories, this.props.match.params.category);
                elements = this.renderSubcategories(this.props.subcategories, categoryID);
            }, 2000)
        }
        return (
            <div className={s.wrapper}>{elements}</div>
        )
    }
}

const mapStateToProps = (state) => ({})
export default connect(mapStateToProps, {thunkGetProductsForTargetSubcategory})(withRouter(Subcategories));