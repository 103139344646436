import React, { Component } from 'react';
import TopBanner from './top-banner';
import UserNavigation from './user-navigation';
import MainHead from './main-head';

export default class Header extends Component {
    render() {
        return(
            <div>
                {/* <TopBanner /> */}
                <UserNavigation />
                <MainHead />
            </div>
        )
    }
}