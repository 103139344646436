import React, { Component } from 'react';
import Header from '../../components/header';
import Way from '../../components/way';
import { AdminLeftMenu } from '../../components/user-left-menu/user-left-menu';
import s from './cabinetPage.module.css';
import { withAuthRedirect, withNotAdminRedirect } from '../../hoc/myHocs';
import { compose } from 'redux';

class AdminCabinetPage extends Component {

    render () {
        let way = [ // Объект для указания пути
            {
                src: '/',
                title: 'Главная'
            },
            {
                src: '/reg',
                title: 'Мой кабинет'
            }
        ]
        return (
            <>
            <Header />
            <Way way={way} />
            <div className='container'>
                <div className='wrapper-left'>
                    <AdminLeftMenu />
                </div>
                <div className='wrapper-right'>
                    <div className={s.wrapper_right__left}>
                        <div className={s.indicators}>
                            <div className={s.cabinet_item}>
                                <div>Поставщиков:</div>
                                <span className={s.float_right}><b>22</b></span>
                            </div>
                            <div className={s.cabinet_item}>
                                <div>Магазинов:</div>
                                <span className={s.float_right}><b>342</b></span>
                            </div>
                            <div className={s.cabinet_item}>
                                <div>Сут. прибыль:</div>
                                <span className={s.float_right}><b>82112</b></span>
                            </div>
                            <div className={s.cabinet_item}>
                                <div>Комиссия:</div>
                                <span className={s.float_right}><b>0.45%</b></span>
                            </div>
                        </div>
                        <div className={s.applications}>
                            <div className={s.applications_title}>Все заявки</div>
                            <div>В ожидании <span className={s.float_right}>0</span></div>
                            <div>В обработке <span className={s.float_right}>0</span></div>
                            <div>На удержании <span className={s.float_right}>0</span></div>
                            <div>Завершенные <span className={s.float_right}>0</span></div>
                            <div>Все заявки <span className={s.float_right}>0</span></div>
                            <div>- - - - - - - - - - - - - - - - - - - - -</div>
                            <div>Отмененные <span className={s.float_right}>0</span></div>
                            <div>Возвраты <span className={s.float_right}>0</span></div>
                        </div>
                    </div>
                    <div className={s.board}>
                        <div>Фюррер</div><br />
                        <div><img width='100%' src='https://cdn.tvc.ru/pictures/o/352/311.jpg' /></div>
                        {/* <div><img width='100%' src='https://историк.рф/uploads/0/2019/10/11/q8p68nRX.jpg' /></div> */}
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default compose (
    withNotAdminRedirect,
    withAuthRedirect
)(AdminCabinetPage);