import React, { Component } from 'react';
import Header from '../../components/header';
import Products from '../../components/products';
import ProductCategories from '../../components/product-categories';
import Way from '../../components/way';

import { WillbeAPI } from '../../api/api';
import { withAuthRedirect } from '../../hoc/myHocs';
import { compose } from 'redux';

import '../../style.css';
import s from './supplierPage.module.css';

class SupplierPage extends Component {

    willbeAPI = new WillbeAPI();

    state = {
        id: null,
        company_name: null,
        address: null,
        year_of_foundation: null,
        description: null,
        min_order_amount: null,
        logo: null,
        cover: null,
        contact_email: null,
        contact_phone_number: null,
        products: []
    }

    componentDidMount() {
        if (this.props.match.params.supplierID) {
            this.willbeAPI.getSupplier(this.props.match.params.supplierID)
                .then(data => {
                    console.log(data)
                    this.setState((state) => ({
                        id: data.id,
                        company_name: data.company_name,
                        address: data.address,
                        year_of_foundation: data.year_of_foundation,
                        description: data.description,
                        min_order_amount: data.min_order_amount,
                        logo: data.logo,
                        cover: data.cover,
                        contact_email: data.contact_email,
                        contact_phone_number: data.contact_phone_number
                    }))
                })
            this.willbeAPI.getAllProductsFromSupplier(this.props.match.params.supplierID)
                .then(data => {
                    console.log(data)
                    this.setState((state) => ({
                        ...state, products: data.products
                    }))
                })
        }
    }

    onDeleteProduct = (product_id) => {
        const conf = window.confirm('Вы уверены, что хотите удалить товар?');
        if (conf) {
            this.willbeAPI.deleteProduct(product_id).then(data => {
                let newArrayProducts = this.state.products.filter((item) => product_id != item.id)
                this.setState((state) => ({...state, products: newArrayProducts}))
            })
        }
    }

    onChangeInStockProduct = (product_id) => {
        this.willbeAPI.changeInStockProduct(product_id).then(data => {
            this.willbeAPI.getAllProductsFromSupplier(this.props.match.params.supplierID)
                .then(data => {
                    console.log(data)
                    this.setState((state) => ({
                        ...state, products: data.products
                    }))
                })
        })
    }

    render() {
        const {id, company_name, address, year_of_foundation, description,
            min_order_amount, logo, cover, contact_email, contact_phone_number} = this.state;
        const way = [{src: '/', title: 'Главная'}, {src: '/', title: 'Поставщики'}, {src: `/suppliers/${id}`, title: company_name}]
        return (
            <>
                <Header />
                <Way way={way}/>
                <div className='container'>
                    <div className='wrapper-left'>
                        <ProductCategories />
                        {/* <LeftBanner /> */}
                    </div>
                    <div className='wrapper-right'>
                        <div className={s.wrapperSupplier}>
                            <div className={s.supplierCover}>
                                {cover ? (<img src={`https://backend.willbe.kz/storage/images/suppliers/cover/${cover}`} />)
                                : (<img src='https://rusfermer.net/wp-content/plugins/fseo-plugins/plugins/common/img/no-photo-big.gif' />)}
                            </div>
                            <div className={s.wrapperSupplier_left}>
                                {logo ? (<img src={`https://backend.willbe.kz/storage/images/suppliers/logo/${logo}`} />)
                                : (<img src='https://rusfermer.net/wp-content/plugins/fseo-plugins/plugins/common/img/no-photo-big.gif' />)}
                            </div>
                            <div className={s.wrapperSupplier_right}>
                                <h2>{company_name}</h2>
                                <p><b>Статус:</b> поставщик, производитель</p>
                                {address && <p><b>Адрес:</b> {address}</p>}
                                {year_of_foundation && <p><b>Год основания:</b> {year_of_foundation} г.</p>}
                                {/* <p><b>Локация деятельности:</b> Астана, Петропавловск, Костанай, Караганда</p> */}
                                {description && <p><b>Дополнительное описание:</b> {description}</p>}
                                {min_order_amount && <p><b>Минимальная сумма заявки:</b> {min_order_amount} тг.</p>}
                                {contact_phone_number && <p><b>Контактный номер телефона:</b> {contact_phone_number}</p>}
                                {contact_email && <p><b>Контактная почта:</b> {contact_email}</p>}
                                <form>
                                    <input type='submit' value='Подписаться' />
                                </form>
                            </div>
                        </div>
                        {this.state.products.length > 0 && <h3>Продукты компании:</h3>}
                        <Products products={this.state.products} onDeleteProduct={this.onDeleteProduct} onChangeInStockProduct={this.onChangeInStockProduct} />
                    </div>
                </div>
            </>            
        )
    }
}

export default compose (
    withAuthRedirect
)(SupplierPage);