import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../components/header';
import Way from '../../../components/way';
import { SupplierLeftMenu } from '../../../components/user-left-menu/user-left-menu';
import { WillbeAPI } from '../../../api/api';
import { withAuthRedirect, withNotUserSupplierRedirect } from '../../../hoc/myHocs';
import { compose } from 'redux';
import LeftBanner from '../../../components/left-banner';
import { Formik } from 'formik';
import * as yup from 'yup';

import s from '../supplierCabinetPage.module.css';
import localStyle from './localStyle.module.css';
import mainStyle from '../../../style.module.css';

class GeographyOfActivityPage extends Component {

    willbeAPI = new WillbeAPI();

    state = {
        regionID: null,
        locations: []
    }

    componentDidMount = async () => {
        let data = await this.willbeAPI.geographyOfActivity_get();
        this.setState((state) => ({
            ...state,
            locations: data
        }))
    }

    findRegionID = (value) => {
        this.setState(state => ({
            ...state, regionID: value.target.value
        }))
    }

    renderRegionsForOptions = (regions) => {
        return regions.map(region => <option key={region.id} value={region.id}>{region.title}</option>)
    }

    renderCitiesForOptions = (cities, regionID) => {
        return cities.map(city => {
            if (regionID == city.region_id) {
                return <option key={city.id} value={city.id}>{city.title}</option>
            }
        })
    }

    addForm = async (formData, {resetForm}) => {
        formData['region'] = this.state.regionID;
        let data = await this.willbeAPI.geographyOfActivity_add(formData);
        resetForm({});
        if (data != '') {
            this.setState((state) => ({
                ...state,
                locations: data
            }))
        }
    }
    
    deleteForm = async (formData) => {
        let res = await this.willbeAPI.geographyOfActivity_delete(formData.city);
        this.setState((state) => ({
            ...state,
            locations: res
        }))
    }

    renderLocations = (array) => {
        return array.map((local) => {
            return (
                <Formik
                    enableReinitialize
                    initialValues={{city: local.city_id}}
                    validateOnBlur
                    onSubmit={this.deleteForm}
                >
                {({ isValid, handleSubmit, dirty }) => (
                    <div className={mainStyle.overflowHidden}>
                        <span>{local.city.title}, {local.region.title}</span>
                        <span className={mainStyle.float_right}>
                            <button className={mainStyle.cursorPointer} disabled={!isValid && !dirty} onClick={handleSubmit} type='submit'>Удалить</button>
                        </span>
                    </div>
                )}
                </Formik>
            )
        })
    }

    render () {
        let way = [ // Объект для указания пути
            {
                src: '/',
                title: 'Главная'
            },
            {
                src: '/reg',
                title: 'Мой кабинет'
            }
        ]

        // Validate for form
        const validationSchema = yup.object().shape({
            region: yup.string().nullable().required('Выберите вариант'),
            city: yup.string().nullable().required('Выберите вариант'),
        })

        let renderLocations = this.renderLocations(this.state.locations);
        let renderRegionsForOptions = this.renderRegionsForOptions(this.props.regions);
        let renderCitiesForOptions = this.renderCitiesForOptions(this.props.cities, this.state.regionID);
        
        return (
            <>
            <Header />
            <Way way={way} />
            <div className={mainStyle.container}>
                <div className={mainStyle.wrapper_left}>
                    <SupplierLeftMenu supplierID={localStorage.getItem('supplier_id')} />
                    {/* <LeftBanner /> */}
                </div>
                <div className={mainStyle.wrapper_right}>
                    <div className={s.board}>
                        Поставщик (География деятельности)
                        <br /><br />
                        {renderLocations}
                        <br />
                        <Formik
                            enableReinitialize
                            initialValues={{
                                region: '',
                                city: '',
                            }}
                            validateOnBlur
                            validationSchema={validationSchema}
                            onSubmit={this.addForm}
                        >
                            {({ values, errors, touched, setFieldValue, handleChange, handleBlur, isValid, handleSubmit, dirty }) => (
                                <div className={localStyle.form}>
                                    {/* <lavel>Область *</lavel> */}
                                    <div>
                                    <select type='select' name='region' value={values.region || 'false'}
                                        onChange={value => {
                                            this.findRegionID(value)
                                            handleChange(value)}
                                        }>
                                        <option value='false' disabled>Выберите область</option>
                                        {renderRegionsForOptions}
                                    </select>
                                    {touched.region && errors.region && <span className={localStyle.error}>{errors.region}</span>}
                                    </div>
                                    <div>
                                        {/* <lavel>Город *</lavel> */}
                                        <select type='select' name='city' onChange={handleChange} value={values.city || 'false'}>
                                            <option value='false' disabled>Выберите город</option>
                                            {renderCitiesForOptions}
                                        </select>
                                        {touched.city && errors.city && <span className={localStyle.error}>{errors.city}</span>}
                                    </div>
                                    <button disabled={!isValid && !dirty} onClick={handleSubmit} type='submit'>Добавить</button>
                                </div>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    regions: state.geolocations.regions,
    cities: state.geolocations.cities
})

export default compose (
    connect(mapStateToProps),
    withNotUserSupplierRedirect,
    withAuthRedirect
)(GeographyOfActivityPage)