import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import s from './product-item.module.css';

export default class ProductItem extends Component {

    state = {
        isInStock: false,
    }

    componentDidMount() {
        let value = this.props.product.isInStock === 1 ? true : false;
        if (value !== this.state.isInStock) {
            this.setState((state) => ({
                isInStock: value
            }))
        }
    }

    onChangeInStockProduct = (product_id) => {
        this.setState((state) => ({isInStock: !state.isInStock}))
        this.props.onChangeInStockProduct(product_id);
    }

    render() {
        let {id, title, isInStock, weight, image, supplier, company_name, price, user_id, category_id, category, subcategory_id} = this.props.product;
        return(
            <div className={s.wrapper}>
                <NavLink to={`/products/${id}`}>
                    {image ? (<img src={`https://backend.willbe.kz/storage/images/products/${image}`} />)
                    : (<img src='https://rusfermer.net/wp-content/plugins/fseo-plugins/plugins/common/img/no-photo-big.gif' />)}
                </NavLink>
                <div className={s.productCompany}><NavLink to={`/suppliers/${supplier.id}`}>{company_name}</NavLink></div>
                <div className={s.productTitle}>
                    <p>{title}</p>
                </div>
                <div className={s.productPrice}>
                    цена: <span className={s.price}>{price}</span> тг.
                </div>
                {supplier.id != localStorage.getItem('supplier_id') ? (
                    <>
                    <div className={s.productQuantity}>
                        <div className={s.productQuantity_left}>
                            <button className={s.less}>
                                -
                            </button>
                            <span className={s.quantity}>1</span>
                            <button className={s.more}>
                                +
                            </button>
                        </div>
                        <div className={s.productQuantity_right}>
                            <span className={s.productPrice}>сумма: </span>
                            <span className={s.summ}>
                                {price}
                            </span>
                            {' тг.'}
                        </div>
                    </div>
                    <div>
                        <button className={s.btnAddProduct}>Добавить в корзину</button>
                    </div>
                    </>
                ) : (
                    <>
                    <div>
                        <NavLink to={`/supplier-cabinet/edit-products/${id}`}>
                            <button className={s.btnEditProduct}>Рекдактировать</button>
                        </NavLink>
                    </div>
                    <div>
                        <button className={s.btnDeleteProduct} onClick={() => this.props.onDeleteProduct(id)}>Удалить</button>
                        <div className={s.inStockProduct}>
                            В наличии
                            <label className={s.switch}>
                                <input type='checkbox' checked={this.state.isInStock}
                                    onClick={() => this.onChangeInStockProduct(id)} />
                                <span className={`${s.slider} ${s.round}`}></span>
                            </label>
                        </div>
                    </div>
                    </>
                )}
            </div>
        )
    }
}