import { WillbeAPI } from '../../api/api';

let GET_PRODUCTS = 'GET_PRODUCTS';
let CLEAN_PRODUCTS = 'CLEAN_PRODUCTS';

let initialState = {
    products: []
}

export const productsReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_PRODUCTS:
            return {
                ...state,
                products: action.products
            }
        case CLEAN_PRODUCTS:
            return {
                ...state,
                products: null
            }
    }
    return state;
}

// Тут же пока-что буду creatActions.

export const getProducts = (products) => ({
    type: GET_PRODUCTS, products
})

// export const cleanProducts = () => ({
//     type: CLEAN_PRODUCTS
// })

// export const thunkCleanProducts = () => (dispatch) => (
//     dispatch(cleanProducts())
// )

export const thunkGetAllProducts = () => (dispatch) => {
    let willbeAPI = new WillbeAPI();
    return willbeAPI.getAllProducts()
        .then(data => {
            dispatch(getProducts(data));
            return data;
        })
}

export const thunkGetAllProductsFromSupplier = (supplier_id) => (dispatch) => {
    let willbeAPI = new WillbeAPI();
    return willbeAPI.getAllProductsFromSupplier(supplier_id)
            .then(data => {
                dispatch(getProducts(data.products));
                return data;
            })
}

export const thunkGetProductsForTargetCategory = (islandID, category_id) => (dispatch) => {
    let willbeAPI = new WillbeAPI();
    return willbeAPI.getProductsForTargetCategory(islandID, category_id)
        .then(data => {
            dispatch(getProducts(data));
            return data;
        })
}

export const thunkGetProductsForTargetSubcategory = (islandID, subcategory_id) => (dispatch) => {
    let willbeAPI = new WillbeAPI();
    return willbeAPI.getProductsForTargetSubcategory(islandID, subcategory_id)
        .then(data => {
            dispatch(getProducts(data));
            return data;
        })
}