import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { thunkGetProductsForTargetCategory } from '../../store/products';
import { thunkGetCategoriesAndSubcategoriesFromIslandID } from '../../store/categories';
import { connect } from 'react-redux';

import s from './product-categories.module.css';

class ProductCategories extends Component {

    onUpdateProducts = (island_id, category_id) => {
        this.props.thunkGetProductsForTargetCategory(island_id, category_id);
    }

    renderCategory(data) {
        return data.map((item) => {
            return (
                <NavLink key={item.id} to={`/categories/${item.local_id}`} activeStyle={{
                    fontWeight: 'bold',
                    color: '#4a7d8d'
                  }} onClick={() => {this.onUpdateProducts(item.island_id, item.id)}}>
                    <li>
                        <img src={`../../menu-images/${item.src}`} alt='' />
                        <div className={s.text}>{item.title}</div>
                    </li>
                </NavLink>
            )
        })
    }

    changeIsland = (select) => {
        console.log(select.target.value)
        this.props.thunkGetCategoriesAndSubcategoriesFromIslandID(select.target.value);
    }

    renderOptions = (array) => {
        return array.map((item) => {
            if (this.props.islandID == item.id) {
                return <option key={item.id} selected value={item.id}>{item.title}</option>
            } else {
                return <option key={item.id} value={item.id}>{item.title}</option>
            }
        });
    }

    render() {
        let options = this.renderOptions(this.props.islands);
        let listElements = this.renderCategory(this.props.categories);
        return (
            <div className={s.wrapper}>
                <div className={s.select_wrapper}>
                    {/* <Formik
                        initialValues={{
                            islands: ''
                        }}
                        validateOnBlur
                        onSubmit={(value) => console.log(value)}
                    >
                        {({ values, errors, touched, setFieldValue, handleChange, handleBlur, isValid, handleSubmit, dirty }) => (
                            <div className={s.welcomeForm}>
                               <div>
                                    <Field as='select' name='islands' className={s.selectInput} onChange={handleChange} >
                                        {options}
                                    </Field>
                                    {touched.islands && errors.islands && <p className={s.error}>{errors.islands}</p>}
                                </div>
                            </div>
                        )}
                    </Formik> */}
                    <select name='islands' onChange={this.changeIsland}>
                        {options}
                    </select>
                </div>
                <div className={s.boxForBtn}>
                    <NavLink to='/suppliers'><button>Все поставщики раздела</button></NavLink>
                </div>
                <div className={s.top}>
                    Категории раздела:
                </div>
                <ul>
                    {listElements}
                </ul>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    islandID: state.categories.islandID,
    islands: state.categories.islands,
    categories: state.categories.categories
});

export default connect(mapStateToProps, {thunkGetProductsForTargetCategory, thunkGetCategoriesAndSubcategoriesFromIslandID})(ProductCategories);