import React, { Component } from 'react';
import ProductItem from '../product-item';

import s from './products.module.css';

export default class Products extends Component {

    renderProducts = (products, onDeleteProduct, onChangeInStockProduct) => {
        return products.map((item) => {
            return <ProductItem key={item.id} product={item} onDeleteProduct={onDeleteProduct} onChangeInStockProduct={onChangeInStockProduct} />
        })
    }

    render() {
        let elements = this.renderProducts(this.props.products, this.props.onDeleteProduct, this.props.onChangeInStockProduct);
        return (
            <div>
                {elements}
            </div>
        )
    }
}