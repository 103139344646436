import { combineReducers } from 'redux';
import { productsReducer } from './products';
import { categoriesReducer } from './categories';
import { authReducer } from './auth';
import { geolocationsReducer } from './geolocations/geolocations';
import { searchReducer } from './search/search-reducer';
import { reducer as formReducer } from 'redux-form';

export default combineReducers({
    auth: authReducer,
    products: productsReducer,
    categories: categoriesReducer,
    geolocations: geolocationsReducer,
    search: searchReducer,
    form: formReducer
});