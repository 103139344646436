import React, { Component } from 'react';
import { Formik, Field } from 'formik';
import Header from '../../../components/header';
import Way from '../../../components/way';
import { thunkGetCategoriesAndSubcategoriesFromIslandID } from '../../../store/categories';
import { SupplierLeftMenu } from '../../../components/user-left-menu/user-left-menu';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withAuthRedirect, withNotUserSupplierRedirect } from '../../../hoc/myHocs';
import * as yup from 'yup';
import { WillbeAPI } from '../../../api/api';

import s from '../../../../src/form-style.module.css';

class AddProductsPage extends Component {

    willbeAPI = new WillbeAPI();
    state = { category: 1 }

    componentDidMount() {
        this.props.thunkGetCategoriesAndSubcategoriesFromIslandID(1);
    }

    findTrueCategoryId = (obj) => {
        this.setState((state) => ({...state, category: obj.target.value}))
    }

    changeIsland = (select) => {
        this.props.thunkGetCategoriesAndSubcategoriesFromIslandID(select.target.value);
    }

    // Render islands for select
    renderIslands = (array) => {
        return array.map((value, i) => <option key={i} value={value.id}>{value.title}</option>)
    }

    // Render categories for select
    renderCategories = (array) => {
        return array.map((value, i) => <option key={i} value={value.id}>{value.title}</option>)
    }

    // Render Subcategories for select
    renderSubcategories = (array) => {
        return array.map((value, i) => {
            if (i === 0) {
                return <option key={i} value='false' disabled defaultValue>Выберите подкатегорию</option>
            }
            if (value.category_id == this.state.category) {
                return <option key={i} value={value.id}>{value.title}</option>
            }
        })
    }

    // This is function to send form
    sendForm = async (value, {resetForm, setFieldValue}) => {
        value.category = this.state.category
        await this.willbeAPI.addProduct(value)
            .then(data => {
                if (data != '') {
                    resetForm({})
                    document.getElementById('fileUpload').value = '';
                    alert('Продукт добавлен, поздравляем!')
                } else {
                    console.log('Error')
                }
            })
    }

    render() {
        let way = [ // Object for will write way
            {
                src: '/',
                title: 'Главная'
            },
            {
                src: '/reg',
                title: 'Мой кабинет'
            }
        ]

        // To render options
        let islandsOptions = this.renderIslands(this.props.islands)
        let categoriesOptions = this.renderCategories(this.props.categories);
        let subcategoriesOptions = this.renderSubcategories(this.props.subcategories);

        // Validate for form
        const validationSchema = yup.object().shape({
            title: yup.string().nullable().required('Объязательное поле'),
            subcategory: yup.string().nullable().required('Выберите вариант'),
            measurement: yup.string().nullable().required('Выберите вариант'),
            weight: yup.string().nullable().required('Объязательное поле'),
            price: yup.string().nullable().required('Объязательное поле'),
        })

        return (
            <>
            <Header />
            <Way way={way} />
            <div className='container'>
                <div className='wrapper-left'>
                    <SupplierLeftMenu supplierID={localStorage.getItem('supplier_id')} />
                    {/* <LeftBanner /> */}
                </div>
                <div className='wrapper-right'>
                    <div className='bord'>
                        <center><h2>Добавить продукт</h2></center>
                        <Formik
                            enableReinitialize
                            initialValues={{
                                title: '',
                                island: this.props.islandID,
                                category: '',
                                subcategory: '',
                                measurement: '',
                                weight: '',
                                price: '',
                                composition: '',
                                nutritional_value: '',
                                description: '',
                                image: ''
                            }}
                            validateOnBlur
                            validationSchema={validationSchema}
                            onSubmit={this.sendForm}
                        >
                            {({ values, errors, touched, setFieldValue, handleChange, handleBlur, isValid, handleSubmit, dirty }) => (
                                <div className={s.welcomeForm}>
                                    <div>
                                        <lavel>Название продукта *</lavel>
                                        <input name='title' className={s.input} onChange={handleChange} onBlur={handleBlur} value={values.title || ''} />
                                        {touched.title && errors.title && <p className={s.error}>{errors.title}</p>}
                                    </div>
                                    <div>
                                        <lavel>Раздел *</lavel>
                                        <Field as='select' name='island' className={s.selectInput}
                                            onChange={(value) => {
                                                this.changeIsland(value)
                                                handleChange(value)
                                            }}>
                                            {islandsOptions}
                                        </Field>
                                        {touched.island && errors.island && <p className={s.error}>{errors.island}</p>}
                                    </div>
                                    <div>
                                        <lavel>Категория *</lavel>
                                        <Field as='select' name='category' className={s.selectInput}
                                            onChange={(value) => {
                                                this.findTrueCategoryId(value)
                                                handleChange(value)}} >
                                            {categoriesOptions}
                                        </Field>
                                        {touched.category && errors.category && <p className={s.error}>{errors.category}</p>}
                                    </div>
                                    <div>
                                        <lavel>Подкатегория *</lavel>
                                        <Field as='select' name='subcategory' className={s.selectInput} value={values.subcategory || 'false'}>
                                            {subcategoriesOptions}
                                        </Field>
                                        {touched.subcategory && errors.subcategory && <p className={s.error}>{errors.subcategory}</p>}
                                    </div>
                                    <div>
                                        <lavel>Вариант измерения *</lavel>
                                        <Field as='select' name='measurement' className={s.selectInput} value={values.measurement || 'false'}>
                                            <option value='false' disabled>Выберите вариант измерения</option>
                                            <option value='1'>Грамм</option>
                                            <option value='2'>Килограмм</option>
                                            <option value='3'>Миллилитр</option>
                                            <option value='4'>Литр</option>
                                        </Field>
                                        {touched.measurement && errors.measurement && <p className={s.error}>{errors.measurement}</p>}
                                    </div>
                                    <div>
                                        <lavel>Масса *</lavel>
                                        <input name='weight' type='number' className={s.input} onChange={handleChange} onBlur={handleBlur} value={values.weight || ''} />
                                        {touched.weight && errors.weight && <p className={s.error}>{errors.weight}</p>}
                                    </div>
                                    <div>
                                        <lavel>Цена *</lavel>
                                        <input name='price' className={s.input} type='number' onChange={handleChange} onBlur={handleBlur} value={values.price || ''} />
                                        {touched.price && errors.price && <p className={s.error}>{errors.price}</p>}
                                    </div>
                                    <div>
                                        <lavel>Изображение</lavel>
                                        <input name='image' className={s.file} type='file' accept='image/*' id='fileUpload' onChange={(e) => {setFieldValue('image', e.target.files[0])}} onBlur={handleBlur} />
                                    </div>
                                    <div>
                                        <lavel>Состав</lavel>
                                        <textarea name='composition' className={s.textarea} onChange={handleChange} onBlur={handleBlur} value={values.composition || ''} />
                                    </div>
                                    <div>
                                        <lavel>Пищевая ценность</lavel>
                                        <textarea name='nutritional_value' className={s.textarea} onChange={handleChange} onBlur={handleBlur} value={values.nutritional_value || ''} />
                                    </div>
                                    <div>
                                        <lavel>Описание</lavel>
                                        <textarea name='description' className={s.textarea} onChange={handleChange} onBlur={handleBlur} value={values.description || ''} />
                                    </div>
                                    <button disabled={!isValid && !dirty} className={s.btnForm} onClick={handleSubmit} type='submit'>Добавить</button>
                                </div>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    islandID: state.categories.islandID,
    islands: state.categories.islands,
    categories: state.categories.categories,
    subcategories: state.categories.subcategories
});

export default compose (
    connect(mapStateToProps, {thunkGetCategoriesAndSubcategoriesFromIslandID}),
    withNotUserSupplierRedirect,
    withAuthRedirect
)(AddProductsPage)