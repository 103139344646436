import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { required, maxLengthCreater } from '../../utilits/validators';
import { Input, SelectCity, InputPhone } from '../../components/common/FormsControls/FormControls';
import s from '../../../src/form-style.module.css';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const maxLength50 = maxLengthCreater(50);

let ForSupplierForm = (props) => {
    return (
        <form className={s.welcomeForm} onSubmit={props.handleSubmit}>
            <div>
                <Field className={s.input} component={Input} name='company' placeholder='Название компании'
                    validate={[required, maxLength50]} />
            </div>
            <div>
                <Field component={SelectCity} validate={[required, maxLength50]} className={s.selectInput} name='city' placeholder='Выберите город' />
            </div>
            <div>
                <Field className={s.input} component={Input} name='address' placeholder='Адрес компании'
                    validate={[required, maxLength50]} />
            </div>
            <div>
                <Field component={InputPhone} name='phone' inputClass={s.inputPhoneNumber}
                    validate={[required, maxLength50]} placeholder='+7 (777) 77-77-77' /> 
            </div>
            <div className={s.authDataWrapper}>
                <center><p>Данные для авторизации</p></center>
                <Field className={s.input} type='email' component={Input} className={s.input} name='email'
                    placeholder='Эл. почта' validate={[required, maxLength50]} />
                <Field className={s.input} type='password' component={Input} className={`${s.input} ${s.marginBottom0}`}
                    name='password' placeholder='Пароль' validate={[required, maxLength50]} />
            </div>
            <div><button className={s.btnForm}>Оставить заявку</button></div>
        </form>
    )
}

export const ForSupplierReduxForm = reduxForm({form: 'requestForRegistartionSupplierForm'})(ForSupplierForm);
